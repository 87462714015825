     /* Manager Modal */
    .device_logout {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      border-radius: 15px;
      background-color: #f8f9fc;
      padding: 1.5rem;
      transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .device_logout .device_logout_bold_text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #000000;
      margin: 0;
    }

    .device_logout .device_logout_light_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #000000;
      margin: 0;
    }

    .device_logout .enter_input {
      background-color: #fff;
      border: 1.4px solid #e4e7eb;
      border-radius: 5px;
      width: 40%;
    }

    .device_logout .enter_input input {
      border: none;
      outline: none;
      text-align: center;
      width: 100%;
      padding: 0.5rem 0;
      border-radius: 5px;
    }

    .device_logout .enter_input input ::placeholder {
      color: #919ec3;
    }

    .device_logout .bottom_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      gap: 0.8rem;
    }

    /* only when it is used for order */
    .bottom_buttons .btn_text {
      text-align: center;
      margin: 0;
      line-height: 1;
      font-size: 12px;
      font-weight: 600;
      margin-top: 4px;
    }

    @media (width<890px) {
      .btn_text {
        display: none;
      }
    }

    /* Manager Device Table */
    .table_container {
      padding: 5px 10px;
      background-color: #f8f9fc;
      border-radius: 15px;
      max-height: 600px;
      overflow-y: scroll;
    }

    .custom_table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
    }

    .custom_table th,
    .custom_table td {
      padding: 12px 24px;
      text-align: left;
    }

    .custom_table th {
      background-color: #d9dce5;
      color: #646b88;
      font-size: 14px;
      font-weight: 600;
      line-height: 18.2px;
    }

    .custom_table td {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      background-color: white;
      margin: 1rem 0;
    }

    .custom_table th {
      background-color: #d9dce5;
      color: #646b88;
      font-size: 14px;
      font-weight: 600;
      line-height: 18.2px;
    }

    .custom_table td {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      background-color: white;
      margin: 1rem 0;
    }

    .custom_table td svg {
      margin-left: 1rem;
    }

    .custom_table tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .custom_table tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .optionIs {
      position: absolute;
      right: 100%;
      z-index: 999;
      top: -50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      width: 155px;
      border-radius: 10px;
    }

    .option_item {
      padding: 5px 10px;
    }

    .darkHover:hover {
      background-color: #1B1D21;
      /* For dark mode */
    }

    .lightHover:hover {
      background-color: #F8F9FC;
      /* For light mode */
    }

    .threeDot {
      position: relative;
      width: 40%;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .continerMain {
      width: 100%;
      height: 100%;
      border: 1px solid;
      padding-left: 10PX;
    }