.compoContiner {
    width: 100%;
    height: calc(100vh - 65px);
    padding: 10px 10px 0 10px;
}

.scandMainCon {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}

.cardDash {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /* Responsive grid */
    gap: 8px;
    width: 100%;
}

.cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}

.mainCompo {
    width: 100%;
}

.mainText h1 {
    /* font-size: 30px;
    font-weight: 600; */
    font-size: 40px;
    /* Adjust the font size */
    color: transparent;
    /* Make the text hollow */
    -webkit-text-stroke: 2px #000;
    /* Outline thickness and color */
    font-weight: bold;
}