/* apps page */
.apps {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.progress {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress>p,
.progress>span {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.progress_bar {
    background-color: #E4E7EB;
    border-radius: 20px;
    position: relative;
    height: 30px;
    width: 300px;
    overflow: hidden;
}

.progress_done {
    background: linear-gradient(90deg, #749AFF 2.08%, #4164E3 100%);
    box-shadow: 0px 4px 4px 0px #4164E340;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 1;
    transition: width 0.5s ease-in-out;
    font-size: 12px;
    min-width: 5%;
}

.apps_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.search_bar {
    max-width: 300px;
}

.loader_con>span:nth-of-type(2) {
    z-index: 2;
    margin-top: 2.5em;
    color: #232529;
    font-size: 30px;
}

.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    height: 0.8em;
    width: 0.8em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
    color: #232529;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.loader:before {
    animation-delay: -0.32s;
    transform: translateX(-150%);

}

.loader:after {
    transform: translateX(50%);
}

@keyframes bblFadInOut {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }

    40% {
        box-shadow: 0 2.5em 0 0
    }
}

.apps_body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    height: 1000%;
}

.apps_app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 10px;
}

.apps_app_left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.img_div {
    height: 115px;
    width: 115px;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid #D9DCE5;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_div>img {
    height: 90%;
    width: 90%;
    border-radius: 15px;
    object-fit: contain;
}

.text_div {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.text_div>div {
    display: flex;
    align-items: center;
}

.text_div>p,
.text_div>div>p {
    margin: 0;
}

.text_div>a:nth-of-type(1) {
    color: #0100E4;
    max-width: 245px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.text_div>a:nth-of-type(1)>span {
    margin-right: 5px;
}

.text_div>div {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #646B88;
    font-size: 14px;
}

.text_div>div>span {
    background-color: #F8F9FC;
    border-radius: 5px;
    text-align: center;
    padding: 8px 10px;
}

.text_div>p:nth-of-type(1) {
    font-weight: 700;
    font-size: 30px;
    color: #000;
}

.text_div>p:nth-of-type(2) {
    font-weight: 400;
    font-size: 16px;
    color: #646B88;
}

.view_more {
    color: #0100E4;
    cursor: pointer;
    text-decoration: none;
}

/* image slider */
.slider_container {
    position: relative;
    /* max-width: 900px; */
    overflow: hidden;
}

.img_slider {
    max-width: 900px;
}

.slider_wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slider_track {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slider_item {
    flex-shrink: 0;
    padding: 5px;
    height: 210px;
}

.slider_image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    user-select: none;
}

.slider_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transition: background 0.3s;
    z-index: 10;
    background-color: #F8F9FC;
}

.slider_arrow.left {
    left: 10px;
}

.slider_arrow.right {
    right: 10px;
}

/* star rating */
.star_rating {
    display: flex;
    gap: 5px;
}

.star_wrapper {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.star {
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
}

.outlined {
    color: gold;
    -webkit-text-stroke: 1px gold;
    color: transparent;
}

.filled {
    color: gold;
    overflow: hidden;
    position: absolute;
}

/* view apps */
.v_app_content {
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.v_app_content_4,
.v_app_content_3 {
    display: flex;
    gap: 10px;
}

.v_app_content_3>div {
    padding: 10px;
    width: 50%;
    background-color: #F8F9FC;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.v_app_content_3>div>ul>li>span {
    font-weight: 500;
}

.v_app_content_3>div>ul>li {
    font-size: 14px;
}

.v_app_content_3>div>p {
    margin: 0;
}

.version_history>p,
.v_app_content_3>div>p:nth-of-type(1) {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
}

.v_app_content_3>div>p:nth-of-type(2) {
    font-weight: 500;
    font-size: 16px;
}

.v_app_content_3>div>p:nth-of-type(3) {
    font-weight: 600;
    font-size: 30px;
    color: #646B88;
}

.v_app_content_3>div>p:nth-of-type(4) {
    font-weight: 400;
    font-size: 16px;
    color: #646B88;
}

.v_app_content_4>div {
    width: 50%;
}

.version_history {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.review_section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
    height: 100%;
}

.review {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: #000;
}

.review>p {
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
}

.review>div:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -10px;
}

.review_photo {
    background-color: #D9D9D9;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.review_user_dets>p {
    margin: 0;
}

.review_user_dets>p:nth-of-type(1) {
    font-weight: 500;
    font-size: 14px;
}

.review_user_dets>p:nth-of-type(2) {
    font-weight: 400;
    font-size: 12px;
    color: #646B88;
}

/* version history accordion */
.version_history_accordion {
    background-color: #F8F9FC;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.version_history_accordion>div:nth-of-type(1)>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.accordion_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.arrow_icon {
    transition: transform 0.3s ease-in-out;
}

.arrow_icon.open {
    transform: rotate(180deg);
}

.accordion_content>p {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin-bottom: 0;
}

.accordion_content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 1px;
    justify-content: space-between;
    flex-grow: 0;
}

.download_btn {
    align-self: flex-end;
    background-color: #4164E3;
    cursor: pointer;
    padding: 0.5em 0.8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    border-radius: 5px;
}

.download_btn>p {
    margin: 0;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 0;
}

.accordion_content.show {
    height: auto;
    opacity: 1;
}

.camera_icon {
    position: absolute;
    top: 80%;
    left: 80%;
    cursor: pointer;
}

.c_app_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;
}

.c_app_form {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.form_right,
.form_left {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radio_options {
    display: flex;
    flex-direction: column;
}

.radio_options>div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.form_left_row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.txt {
    margin: 0;
    font-size: 14px;
    color: #646B88;
}

.txt>span {
    color: red;
}

.textarea {
    font-size: 12px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
    margin-top: 0.4rem;
}

.textarea:focus {
    border: 1px solid #4164E3;
}

.textarea_dark {
    background-color: #1b1d21;
    border-color: #1b1d21;
    color: #ffffff;
}

.form_right {
    width: 70%;
}

@media (max-width: 700px) {
    .apps {
        height: 100%;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        overflow: auto;
        padding: 0;
    }

    .apps_top {
        padding: 10px 10px 0 10px;
    }

    .apps_top>div:nth-of-type(1) {
        display: none;
    }

    .apps_top>div:nth-of-type(2) {
        margin-left: auto;
    }

    .search_bar {
        margin-left: 10px;
    }

    .apps_body {
        padding: 0 10px;
        padding-bottom: 10px;
    }
}

@media (width<1540px) {
    .apps_app {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .img_slider {
        max-width: 100%;
    }
}

@media (width<1160px) {
    .c_app_form {
        flex-direction: column;
    }

    .form_left {
        width: 100%;
    }

    .form_right {
        width: 100%;
    }
}

@media (width<1000px) {
    .v_app_content_3 {
        flex-direction: column;
    }

    .v_app_content_4 {
        flex-direction: column-reverse;
    }

    .v_app_content_3>div,
    .v_app_content_4>div {
        width: 100%;
    }

    .review_section {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
    }

}