 /* Wrapper.module.css */
 .modalOverlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   backdrop-filter: blur(8px);
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   visibility: hidden;
   /* overflow: hidden; */
   transition: opacity 0.5s ease, visibility 0.5s ease;
   z-index: 9999;
 }

 .modalOverlay.open {
   opacity: 1;
   visibility: visible;
 }

 .modalOverlay.close {
   opacity: 0;
   visibility: hidden;
 }

 .modalContent {
   transition: transform 0.5s ease, opacity 0.5s ease;
   transform: translateY(-50px);
   opacity: 0;
 }

 .modalOverlay.open .modalContent {
   transform: translateY(0);
   opacity: 1;
 }

 .modalOverlay.close .modalContent {
   transform: translateY(-50px);
   opacity: 0;
 }













 .drawer {
   position: absolute;
   bottom: 0%;
   left: 0;
   width: 100%;
   height: auto;
   background-color: transparent;
   transition: bottom 0.3s ease, opacity 0.3s ease;
   box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
   visibility: hidden;
   opacity: 0;
   z-index: 999;
   border-radius: 10px 10px 0px 0px;
 }

 .drawer.open {
   bottom: 0;
   visibility: visible;
   opacity: 1;
 }

 .drawerContent {
   /* padding: 20px; */
   z-index: 999;
 }