/* input tag styles */

.login_Form_element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
}

.login_Form_label {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #646b88;
}

.login_Form_input {
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #e4e7eb;
  padding: 16px 12px 16px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background-color: #fff;
}

.login_Form_input::placeholder {
  color: #9f9f9f;
}

/* dropdown part 2 */
.drop_down_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.drop_down_label {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.drop_down_select {
  position: relative;
  min-width: 90px;
  max-width: 120px;
}

.drop_down_select1 {
  position: relative;
  /* min-width: 90px; */
  width: 100px;
}

.drop_down_trigger {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.2rem 0.5rem 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  border: 0.5px solid #e4e7eb;
  color: #646b88;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}

.drop_down_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #646b88;
}

.drop_down_placeholder p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.drop_down_list {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 2px 0;
}

.drop_down_item {
  padding: 8px 10px;
  font-size: 12px;
  color: #646b88;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #e4e7eb;
  transition: background-color 0.2s ease;
}

.drop_down_item:hover {
  background-color: #e4e7eb;
}

.drop_down_item_dark {
  color: #ffffff;
  border-bottom: 1px solid #1b1d21;
}

.drop_down_item_dark:hover {
  background-color: #333;
}

/* search bar */

/* .navbar_Search_Bar {
  gap: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #e4e7eb;
  background-color: #fff;
}

.search_Bar_Input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: #646b88;
}

.search_Bar_Input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.6px;
  color: #ccc;
}

.search_Bar_Input_Dark {
  background-color: #1b1d21;
  color: #ffffff;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.search_Bar_Input_Dark::placeholder {
  color: #7e7e7e;
} */

/* toggle search bar */
.t_s_con {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  transition: width 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
  border-radius: 5px;
  padding: 0 0.25rem;
  border: 1px solid #E4E7EB;
  background-color: #fff;
}

.t_s_con_dark {
  background-color: #1b1d21;
  border-color: #1b1d21;
}

.collapsed {
  width: 40px;
  height: 40px;
}

.expanded {
  width: 240px;
  height: 40px;
}

.hidden {
  display: none;
}

.t_s_Button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.t_s_inp {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

.t_s_inp_dark {
  color: #FFFFFF;
}

.t_s_inp:placeholder {
  color: #646B88;
}

/* text element */

.text_element {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text_element p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.text_element {
  position: relative;
}

.designation_wrapper {
  position: relative;
  display: inline-block;
}

.designation_wrapper p {
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 1;
}

.hidden_designations {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px 0px #00000040;
  z-index: 1;
  white-space: nowrap;
  border-radius: 10px;
  text-align: left;
}

.hidden_designations_item {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, padding 0.3s ease, margin 0.3s ease;
}

.hidden_designations_item:hover {
  background-color: #F8F9FC;
}

.hidden_designations_item.hidden_designations_item_dark:hover {
  background-color: #1b1d21;
}

.designation_wrapper:hover .hidden_designations {
  display: block;
}

/* toggle btn */

.toggle_btn {
  display: flex;
  gap: 1rem;
}

.toggle_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}

.toggle_button {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle_button.active {
  background-color: #4164e3;
}

.slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle_button.active .slider {
  transform: translateX(19px);
}

.toggle_btn_p {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 500;
  width: 25px;
  color: #000000;
}

/* radio button group */

.radioGroup {
  display: flex;
  gap: 1.5rem;
}

.radioItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 5px;
}

.radioInput {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.radioCircle {
  width: 20px;
  height: 20px;
  border: 2px solid #4164e3;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease;
}

.radioInput:checked+.radioCircle {
  background-color: #e0e7ff;
  border-color: #4164e3;
}

.radioCircle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #4164e3;
  border-radius: 50%;
  display: none;
}

.radioInput:checked+.radioCircle::before {
  display: block;
}

.radioLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #646b88;
  transition: color 0.3s ease;
}


/* vertical dropdown */

.vertical_dropdown_container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-start;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.vertical_dropdown_label {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #646b88;
}

.vertical_custom_select {
  position: relative;
  /* border: 1.4px solid #ccc; */
  border: 1px solid;
  width: 100%;
  border-radius: 4px;
}

.vertical_dropdown_placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.vertical_dropdown_placeholder span {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.vertical_dropdown_trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  background-color: lightcoral;
}

.vertical_dropdown_list {
  background-color: red;
  position: absolute;
  top: 100%;
  left: 0;
  /* background-color: #fff; */
  border: 1px solid #e4e7eb;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.focused_item {
  background-color: #e4e7eb;
  color: #000;
}

.focused_item_dark {
  background-color: #333333;
  color: #ffffff;
}

.vertical_dropdown_item {
  padding: 8px 16px;
  font-size: 14px;
  color: #646b88;
  cursor: pointer;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .vertical_dropdown_item {
  padding: 8px 16px;
  font-size: 14px;
  color: #646b88;
  cursor: pointer;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e7eb;
} */

.vertical_dropdown_item>span:nth-of-type(2) {
  margin: 0;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;

  text-align: right;
  color: #7698FF;
  /* font-size: 10px; */
  /* color: lightblue; */
}

.vertical_dropdown_item:hover {
  background-color: #e4e7eb;
}

.vertical_dropdown_item_dark {
  color: #ffffff;
  border-bottom: 1px solid #1b1d21;
}

.vertical_dropdown_item_dark:hover {
  background-color: #333;
}

.selected_items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.selected_item {
  display: flex;
  align-items: center;
  background-color: #e4e7eb;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #646b88;
}

.remove_item {
  margin-left: 0.5rem;
  cursor: pointer;
}

/* assign role  */

.tabs_section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  background-color: #fff;
  margin-top: -0.6rem;
  border-radius: 4px;
}

.tabs {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tab {
  color: #4164e3;
  background-color: #f8f9fc;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tab svg {
  margin-left: 0.2rem;
}

.section_border {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.add_des {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f8f9fc;
  padding: 1rem;
  border-radius: 10px;
}

.add_des p {
  margin: 0;
  font-size: 24px;
}


.label {
  font-size: 12px;
}

.inputRedio[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  position: relative;
  top: 6px;
  border: 2px solid #4164E3;
  cursor: pointer;
}

.inputRedio[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #4164E3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.5s;
}


@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
  }

  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #64aedf;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #4164E3;
  }

  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #4164E3;
  }
}



/* Input Type file  */

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.labelfile {
  font-size: 16px;
  color: #f0f0f0;
  background-color: #000;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.labelfile:hover {
  background-color: #333;
}

.inputFile {
  display: none;
}




/* Toolbar styles */
.toolbar {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.toolbar button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.toolbar button:hover {
  background-color: #e9e9e9;
}

/* Editor styles */
.editor {
  min-height: 100px;
  border: 1px solid #ccc;
  outline: none;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  margin: 0px 10px;
}


/* Chack box */

.checkboxWrapper {
  --borderColor: #4164E3;
  --borderWidth: .1em;

}

.checkboxWrapper label {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.checkboxWrapper input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: middle;
  font-size: 1.5em;
  border-radius: 0.125em;
  display: inline-block;
  /* padding-left: 5px; */
  border: var(--borderWidth) solid var(--borderColor);
  width: 1em;
  height: 1em;
  position: relative;
}

.checkboxWrapper input[type=checkbox]:before,
.checkboxWrapper input[type=checkbox]:after {
  content: "";
  position: absolute;
  background: var(--borderColor);
  width: calc(var(--borderWidth) * 3);
  height: var(--borderWidth);
  top: 50%;
  left: 10%;
  transform-origin: left center;
}

.checkboxWrapper input[type=checkbox]:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
  transition: transform 200ms ease-in 200ms;
}

.checkboxWrapper input[type=checkbox]:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
  transform-origin: left center;
  transition: transform 200ms ease-in;
}

.checkboxWrapper input[type=checkbox]:checked:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
  transition: transform 200ms ease-in;
}

.checkboxWrapper input[type=checkbox]:checked:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
  transition: transform 200ms ease-out 200ms;
}

.checkboxWrapper input[type=checkbox]:focus {
  outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
}

/* spinner */

.spinner {
  border-radius: 50%;
  display: inline-block;
  animation: spin 1s linear infinite;
  border-style: solid;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* ----------- editer css ----------- */

.editerCointner {
  width: 100%;
}

.editerCointner1 {
  width: 100%;
  padding: 1%;
}

.templateBody {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  outline: none;
  font-size: 13px;
}

.templateBody::placeholder {
  font-size: 13px;
}

.para {
  line-height: 0%;
  margin-left: 10px;
  color: #9f9f9f;
  font-size: 13px;
}

.star {
  color: rgb(255, 0, 0);
}

.btnCon {
  width: 100%;
  line-height: 0%;
  display: flex;
  justify-content: space-between;
}

.btnContiner {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.txtEditor_btn {
  display: flex;
  gap: 20px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  line-height: 0%;
  margin-top: -10px;
}

.templateBody_count {
  margin-left: 20px;
  font-size: 12px;
}

.icon_css {
  cursor: pointer;
  font-size: 12px;
}

.icon_css1 {
  cursor: pointer;
  position: relative;
}

.po {
  position: absolute;
  top: 20px;
  right: 1px;
  /* z-index: 999; */
}

.emojis_div {
  /* border: 1px solid ; */
  width: 300px;
  height: 200px;
  overflow-y: scroll;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emojis_div1 {
  margin-top: 10px;
  width: 100%;
}

.emojis_icon_css {
  /* border: 1px solid; */
  width: 30px;
  height: 30px;
  margin: 5px;
  font-size: 21px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imogy {
  width: 100%;
  /* height: 200px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}


/* *Multi Select Drop Down */

.dropdownContainer {
  max-width: 200px;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.arrowIcon {
  transition: transform 0.3s;
}

.dropdownHeader:hover {
  background-color: #eaeaea;
}

.dropdownOptions {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  max-width: 200px;
  /* width: 100%; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  padding: auto;
}

.optionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.optionLabel input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
}

.optionLabel label {
  cursor: pointer;
}

.inputContainer {
  display: flex;
  align-items: center;
}











/* FromComponent.module.css */

.inputTypeDate1::-webkit-calendar-picker-indicator {
  color: #4164e3;
}

.inputTypeDate1::placeholder {
  color: #000000;
  opacity: 1;
}

.login_Form_element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
}

.login_Form_label {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #646b88;
}

.login_Form_input {
  width: 100%;
  border: none;
  outline: none;
  border: 1px solid #e4e7eb;
  padding: 16px 12px 16px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  background-color: #fff;
}

.login_Form_input::placeholder {
  color: #9f9f9f;
}

/* dropdown part 2 */
.drop_down_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.drop_down_label {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.drop_down_select {
  position: relative;
  min-width: 90px;
  max-width: 120px;
}

.drop_down_trigger {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.2rem 0.5rem 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  border: 0.5px solid #e4e7eb;
  color: #646b88;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}

.drop_down_trigger1 {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.2rem 0.5rem 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  border: 0.5px solid #e4e7eb;
  color: #646b88;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;

}

.drop_down_placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: #646b88;
}

.drop_down_placeholder p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop_down_list {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 2px 0;
}

.drop_down_item {
  padding: 8px 10px;
  font-size: 12px;
  color: #646b88;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  border-bottom: 1px solid #e4e7eb;
  transition: background-color 0.2s ease;
}

.drop_down_item:hover {
  background-color: #e4e7eb;
}

.drop_down_item_dark {
  color: #ffffff;
  border-bottom: 1px solid #1b1d21;
}

.drop_down_item_dark:hover {
  background-color: #333;
}

/* search bar */

.navbar_Search_Bar {
  gap: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #e4e7eb;
  background-color: #fff;
}

.search_Bar_Input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: #646b88;
}

/* .search_Bar_Input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.6px;
  color: #646B88;
} */

.search_Bar_Input_Dark {
  background-color: #1b1d21;
  color: #ffffff;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

/* .search_Bar_Input_Dark::placeholder {
  color: #7e7e7e;
} */

/* text element */

.text_element {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text_element p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.text_element {
  position: relative;
}

.designation_wrapper {
  position: relative;
  display: inline-block;
}

.designation_wrapper p {
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 1;
}

.hidden_designations {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px 0px #00000040;
  z-index: 1;
  white-space: nowrap;
  border-radius: 10px;
  text-align: left;
}

.hidden_designations_item {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, padding 0.3s ease, margin 0.3s ease;
}

.hidden_designations_item:hover {
  background-color: #F8F9FC;
}

.hidden_designations_item.hidden_designations_item_dark:hover {
  background-color: #1b1d21;
}

.designation_wrapper:hover .hidden_designations {
  display: block;
}

/* toggle btn */

.toggle_btn {
  display: flex;
  gap: 1rem;
}

.toggle_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}

.toggle_button {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle_button.active {
  background-color: #4164e3;
}

.slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle_button.active .slider {
  transform: translateX(19px);
}

.toggle_btn_p {
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 500;
  width: 25px;
  color: #000000;
}

/* radio button group */

.radioGroup {
  display: flex;
  gap: 1.5rem;
}

.radioItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 5px;
}

.radioInput {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.radioCircle {
  width: 20px;
  height: 20px;
  border: 2px solid #4164e3;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  transition: all 0.3s ease;
}

.radioInput:checked+.radioCircle {
  background-color: #e0e7ff;
  border-color: #4164e3;
}

.radioCircle::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #4164e3;
  border-radius: 50%;
  display: none;
}

.radioInput:checked+.radioCircle::before {
  display: block;
}

.radioLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #646b88;
  transition: color 0.3s ease;
}


/* vertical dropdown */

.vertical_dropdown_container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-items: flex-start;
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: relative;
}

.ErrorText {
  width: 100%;
  line-height: 1;
  /* border: 1px solid; */
  position: absolute;
  top: 100%;
  right: 0;

}

.ErrorText span {
  font-size: 12px;
  font-weight: 400;
  float: right;
  color: red;
}

.vertical_dropdown_label {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #646b88;
  line-height: 1;
  margin: 0;
}

.vertical_custom_select {
  position: relative;
  border: 1px solid #e4e7eb;
  width: 100%;
  border-radius: 4px;
}

.vertical_dropdown_placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.vertical_dropdown_placeholder span {
  color: #9C9797;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.vertical_dropdown_trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  color: #9C9797;
  /* background-color: blue; */
}

.vertical_dropdown_list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 15px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.vertical_dropdown_item {
  padding: 8px 16px;
  font-size: 14px;
  color: #646b88;
  cursor: pointer;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e4e7eb;
}

.vertical_dropdown_item:hover {
  background-color: #e4e7eb;
}

.vertical_dropdown_item_dark {
  color: #ffffff;
  border-bottom: 1px solid #1b1d21;
}

.vertical_dropdown_item_dark:hover {
  background-color: #333;
}

.selected_items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.selected_item {
  display: flex;
  align-items: center;
  background-color: #e4e7eb;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #646b88;
}

.remove_item {
  margin-left: 0.5rem;
  cursor: pointer;
}

/* assign role  */

.tabs_section {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  background-color: #fff;
  margin-top: -0.6rem;
  border-radius: 4px;
}

.tabs {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tab {
  color: #4164e3;
  background-color: #f8f9fc;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.tab svg {
  margin-left: 0.2rem;
}

.section_border {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.add_des {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f8f9fc;
  padding: 1rem;
  border-radius: 10px;
}

.add_des p {
  margin: 0;
  font-size: 24px;
}


.label {
  font-size: 12px;
}

.inputRedio[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  position: relative;
  top: 6px;
  border: 2px solid #4164E3;
  cursor: pointer;
}

.inputRedio[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: #4164E3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.5s;
}


@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
  }

  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #64aedf;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #4164E3;
  }

  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #4164E3;
  }
}



/* Input Type file  */

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.labelfile {
  font-size: 16px;
  color: #f0f0f0;
  background-color: #000;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.labelfile:hover {
  background-color: #333;
}

.inputFile {
  display: none;
}




/* Toolbar styles */
.toolbar {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.toolbar button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.toolbar button:hover {
  background-color: #e9e9e9;
}

/* Editor styles */
.editor {
  min-height: 100px;
  border: 1px solid #ccc;
  outline: none;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  font-family: Arial, sans-serif;
  margin: 0px 10px;
}


/* Chack box */

.checkboxWrapper {
  --borderColor: #4164E3;
  --borderWidth: .1em;

}

.checkboxWrapper label {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.checkboxWrapper input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: middle;
  font-size: 1.5em;
  border-radius: 0.125em;
  display: inline-block;
  /* padding-left: 5px; */
  border: var(--borderWidth) solid var(--borderColor);
  width: 1em;
  height: 1em;
  position: relative;
}

.checkboxWrapper input[type=checkbox]:before,
.checkboxWrapper input[type=checkbox]:after {
  content: "";
  position: absolute;
  background: var(--borderColor);
  width: calc(var(--borderWidth) * 3);
  height: var(--borderWidth);
  top: 50%;
  left: 10%;
  transform-origin: left center;
}

.checkboxWrapper input[type=checkbox]:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(0);
  transition: transform 200ms ease-in 200ms;
}

.checkboxWrapper input[type=checkbox]:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(0);
  transform-origin: left center;
  transition: transform 200ms ease-in;
}

.checkboxWrapper input[type=checkbox]:checked:before {
  transform: rotate(45deg) translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2)) scaleX(1);
  transition: transform 200ms ease-in;
}

.checkboxWrapper input[type=checkbox]:checked:after {
  width: calc(var(--borderWidth) * 5);
  transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2)) scaleX(1);
  transition: transform 200ms ease-out 200ms;
}

.checkboxWrapper input[type=checkbox]:focus {
  outline: calc(var(--borderWidth) / 2) dotted rgba(0, 0, 0, 0.25);
}

/* spinner */

.spinner {
  border-radius: 50%;
  display: inline-block;
  animation: spin 1s linear infinite;
  border-style: solid;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* ----------- editer css ----------- */

.editerCointner {
  width: 100%;
}

.editerCointner1 {
  width: 100%;
  padding: 1%;
}

.templateBody {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  outline: none;
  font-size: 13px;
}

.templateBody::placeholder {
  font-size: 13px;
}

.para {
  line-height: 0%;
  margin-left: 10px;
  color: #9f9f9f;
  font-size: 13px;
}

.star {
  color: rgb(255, 0, 0);
}

.btnCon {
  width: 100%;
  line-height: 0%;
  display: flex;
  justify-content: space-between;
}

.btnContiner {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.txtEditor_btn {
  display: flex;
  gap: 20px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  line-height: 0%;
  margin-top: -10px;
}

.templateBody_count {
  margin-left: 20px;
  font-size: 12px;
}

.icon_css {
  cursor: pointer;
  font-size: 12px;
}

.icon_css1 {
  cursor: pointer;
  position: relative;
}

.po {
  position: absolute;
  top: 20px;
  right: 1px;
  /* z-index: 999; */
}

.emojis_div {
  /* border: 1px solid ; */
  width: 300px;
  height: 200px;
  overflow-y: scroll;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emojis_div1 {
  margin-top: 10px;
  width: 100%;
}

.emojis_icon_css {
  /* border: 1px solid; */
  width: 30px;
  height: 30px;
  margin: 5px;
  font-size: 21px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imogy {
  width: 100%;
  /* height: 200px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}


/* *Multi Select Drop Down */

.dropdownContainer {
  max-width: 200px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  border-radius: 5px;
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0 10px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.arrowIcon {
  transition: transform 0.3s;
}

.dropdownHeader:hover {
  background-color: #eaeaea;
}

.dropdownOptions {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
}

.optionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
}

.optionLabel input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
}

.optionLabel label {
  cursor: pointer;
}


.inputContainer {
  display: flex;
  align-items: center;
}











/* FromComponent.module.css */

.inputTypeDate1::-webkit-calendar-picker-indicator {
  color: #4164e3;
}




.dateWrapper {
  position: relative;
}

.inputTypeDate {
  z-index: 1;
  /* Ensures the input remains on top */
}

.calendarIcon {
  position: absolute;
  right: 10px;
  /* Adjust to your layout */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  /* Icon should remain clickable */
}

.inputContainer {
  width: 100%;
  /* border: 1px solid; */
}

.dateWrapper {
  outline: none;
  border: none;
}

/* .dateInputWrapper{
  min-width:  179px;
  } */