.receipts {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.receipts_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 0 10px;
}

.receipts_top p {
    margin: 0;
}

.receipts_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.receipts_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.receipts_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.receipts_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.receipts_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.receipts_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.receipts_filter_dark {
    border: 1px solid #1B1D21;
}

.receipts_filter:hover {
    border-color: #4164E3;
}

.receipts_filter_dark:hover {
    border-color: #656565;
}

.receipts_filter_active_dark {
    border-color: #656565;
}

.receipts_filter_active {
    border-color: #4164E3;
}

.receipts_bottom {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 10px;
    max-height: calc(100vh - 340px);
}

.receipts_bottom_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receipts_bottom_header>div:nth-of-type(1) {
    width: 250px;
}

.receipts_bottom_filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_data {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.receipts_data_header,
.receipts_data_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    /* z-index: 1; */
}

.receipts_data_row {
    position: relative;
}

.receipts_data_header {
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 500;
}

.receipts_data_header_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.receipts_data_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.receipts_data_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    padding-bottom: 4rem;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.options {
    transition: 0.2s ease-in;
    position: absolute;
    right: -5px;
}

.desc {
    color: #000000;
    font-size: 12px;
    width: 100%;
    padding: 0 0.5rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

/* .options:hover {
    transform: scale(0.8);
} */

.options_menu {
    background-color: #fff;
    color: #000000;
    list-style: none;
    width: 6rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px #00000040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;
    /* right: 1rem; */
    z-index: 100;
}

.options_menu_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.options_menu_li {
    font-size: 12px;
    width: 100%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.options_menu_li:hover {
    background-color: #e4e7eb;
}

.options_menu_li_dark:hover {
    background-color: #333;
}

/* cancel receipt modal  */
.cancelled_receipt_modal,
.cancel_receipt_modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 10px;
}

.cancel_receipt_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cancel_receipt_modal>p {
    margin: 0;
}

.cancel_receipt_head {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.cancel_receipt_p_txt {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.receipt_dets {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 400;
    font-size: 12px;
}

.cancel_receipt_modal>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 0.5rem;
    border-radius: 5px;
    color: #1b1d21;
    font-weight: 400;
}

.cancel_receipt_modal>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.cancel_receipt_modal>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cancelled_receipt_modal {
    width: 370px;
}

.cancelled_receipt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.cancelled_receipt>p:nth-of-type(1) {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    align-self: flex-start;
    margin: 0;
}

.cancelled_receipt>p:nth-of-type(2) {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}