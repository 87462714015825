.refunds {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    position: relative;
}

.refunds_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bank_dd {
    width: 180px;
}

.refunds_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.filter:hover {
    border-color: #4164e3;
}

.filter_dark {
    border-color: #1b1d21;
}

.filter_dark:hover {
    border-color: #656565;
}

.filter_active_dark {
    border-color: #656565;
}

.filter_active {
    border-color: #4164e3;
}

.rem_filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.refunds_radio_btns,
.d_f {
    display: flex;
    align-items: center;
    gap: 10px;
}

.refunds_radio_btns>p {
    font-weight: 400;
    font-size: 14px;
    color: #646b88;
    margin: 0;
    line-height: 0;
}

.data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.data_list_row,
.data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.data_list_row_dark {
    background-color: #1b1d21;
    color: #fafafa;
}

.data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #d9dce5;
    color: #646b88;
}

.data_list_row_header_dark {
    background-color: #232529;
    color: #fafafa;
}

.data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    max-height: calc(100vh - 420px);
    padding-bottom: 10px;
}

.no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px -4px 4px 0px #0000001a;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* refund modal */

.refund_modal {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
}

.refund_modal>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 400px;
}

.border {
    height: 1px;
    width: 100%;
    background-color: #e4e7eb;
}

.border_dark {
    background-color: #474950;
}

.refund_modal>div>p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.element {
    display: flex;
    flex-direction: column;
}

.element>p:nth-of-type(1) {
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 500;
    color: #646b88;
}

.element>p:nth-of-type(2) {
    margin: 0;
    line-height: 1.2;
    font-size: 14px;
    font-weight: 600;
}