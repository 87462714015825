.purchase_order,
.adjustment_order,
.transfer,
.production {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.purchase_order>.scroll_wrapper>.form,
.adjustment_order>.scroll_wrapper>.form,
.transfer>.scroll_wrapper>.form,
.production>.scroll_wrapper>.form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
}

.p_form,
.a_form,
.t_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
}

.p_form>div {
    width: 25%;
}

.a_form>div {
    width: 50%;
}

.t_form>div {
    width: 33%;
}

.production_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.production_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.production_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 12rem;
}

.loader_con {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 36vh;
}

.entry .inp_div {
    width: 100%;
    position: relative;
}

.item_inp {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
}

.item_inp_dark {
    color: #FFFFFF;
}

.p_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.p_table_header,
.p_table_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.p_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.p_table_header_dark {
    color: #FFFFFF;
}

.p_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.p_table_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entry {
    flex: 2;
}

.item_no {
    flex: 6;
    position: relative;
}

.entry_2 {
    flex: 2;
    width: 150px;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
}

.unit_dd {
    background-color: transparent;
    max-width: 80px;
    border-radius: 5px;
    color: #000;
    height: 30px;
    text-align: left;
}

.unit_dd_dark {
    color: #FFFFFF;
}

.unit_dd_option {
    background-color: #FFFFFF;
    text-align: left;
    border: none;
}

.unit_dd_option_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.unit_dd:focus {
    border: 1px solid #4164E3;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
    width: auto;
    margin-top: 0.5rem;
}

.footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
    display: flex;
}

.footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.footer_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.receipt_data_header,
.receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.footer_right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.footer_right_1 {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 320px;
}

.footer_right_1_dark {
    background-color: #1B1D21;
    color: #fff;
}

.footer_right_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer_right_1>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.footer_right_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

/* search supplier */
.search_supplier {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}

.search_supplier_dark {
    background-color: #232529;
    color: #fff;
}

.search_supplier>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_supplier>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_supplier>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_supplier>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* imp in search  */
.selected_row_dark {
    background-color: #d3e0ff;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
}

/* add_supplier_modal */
.add_supplier_modal {
    background-color: #F8F9FC;
}

.so_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_check>p {
    margin: 0;
    line-height: 1;
}

.so_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}