.bg_white {
    /* background-color: #fff; */
    /* width: 100%; */
}

.bg_dark {
    /* background-color: #000000; */
    /* background-color: #000000; */
    background-color: black;
    color: #fff;
    width: 100%;
    padding: 10 14px;
}
.mgx_top_left_go_back {
  display: flex;
  cursor: pointer;
}
.mgx_top_left_go_back {
    display: flex;
    cursor: pointer;
  }
.heading {
    /* font-family: 'Playfair Display'; */
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.08em;
    margin: 15px 0;
    /* text-align: center; */
}

/* .packingHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
} */

.packingHeading h2 {
    margin: 0;
    width: 90%;
}

.customers {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;
    /* margin: 0 5px; */
}

/* .customers th, td {
    border: 1px solid;
} */

.bg th {
    border: none;
    padding: 8px;
    background: #1E2022;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg2 th {
    border: none;
    padding: 8px;
    background: #f2f2f2;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.bg td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #1E2022;
    text-align: center;
}

.cursor tr {
    cursor: pointer;
    margin-top: 20px;
}

.bg2 td {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    border: none;
    padding: 8px;
    background: #f2f2f2;
    text-align: center;
}

.customers th {
    padding-top: 12px;
    padding-bottom: 12px;

}

.customers td {
    padding: 10px 2px;

}

.packingTd {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;
    border: 1px solid;


}

.packingTd td {
    padding: 10px 2px;
    border: 1px solid;
}

/* -----------------------voucher---------------------- */
.voucher_main {
    width: 75%;
    margin: auto;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 50px;
    margin-top: 20px;
}

.voucher_maindiv {
    width: 100%;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    position: relative;
    height: calc(100vh - 70px);
}

.backArrow {
    cursor: pointer;
    text-decoration: underline;
}

.voucher_heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.08em;
    text-align: center;
    /* margin: 0 0 15px 0; */
}

.voucher_flex1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    padding: 1px;
    
   
}
.voucher_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
   border-radius: 20px;
    /* padding: 2px; */
   
}
.packingvoucherth{

    position: sticky;  /* Makes the element stick to the viewport during scrolling */
    /* top: 0;      */
           /* The element will stick to the top of its container */
    /* z-index: 1;   */
          /* Sets the stack order of the element */

padding: 8px;
}
.voucher_flex1{
    display: flex;
    align-items: center;
    gap: 120px;
    white-space: nowrap;
}
.packingvouchertablediv {
    width: 100%;
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  /* cursor: pointer; */
  border-radius: 5px;
}

.packingvouchertable {
     width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}


.voucherFlexDirection {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.packingVoucher_left {
    min-width: 300px;
    /* margin-top: 34px; */
}

.slipheading_div {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    /* margin: 10px; */
    margin-right: 500px;
    /* margin-bottom: 10px; */
    /* align-content: flex-start; */
}

.sliptext {
    font-weight: 300;
    font-size: 14px;
    color: #000000;
}
.sliptext1 {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.sliptextGustin {
    font-weight: 400;
    font-size: 14px;
    color: black;
    text-transform: uppercase;
}

.slipDetailsTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: black;
    /* width: 50%; */
    padding-top: 8px;
    
}

.slipDetailsData {
    font-weight: 300;
    font-size: 14px;
    /* line-height: 10px; */
    color: black;
    /* width: 50%; */
    /* padding-top: 6px; */
}

.slipDetailsDataSelect {
    border: 1px solid #E4E7EB;
    border-radius: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding: 8px;
    /* text-align: left; */
    margin-top: 4px;
  /* margin-right: 20PX; */

}
.slipDetailsDataSelect1 {
    /* border: 1px solid; */
    font-weight: 300;
    font-size: 14px;
    line-height: 10px;
    color: black;
    width: 75%;
    padding-top: 6px;
    text-align: left;
}

.slipDetailsDataSelect option {
    background-color: #1E2022;
    
}

.selectheading {
    font-weight: 400;
    font-size: 20px;
    margin: 20px 0 4px 0;
}

.select {
    width: 358px;
    height: 44px;
    border: 0.826924px solid #F3F3F3;
    border-radius: 2px;
    outline: none;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    background-color: #1E2022;
    text-align: left;
}


.btn_div {
    display: flex;
    gap: 10px;
    /* margin-top: 20px; */
    align-items: center;
    
    /* border: 1px solid red; */
    padding: 10px 10px;
    height: auto;
    
    position: relative;

}
.packingvouchertd{
    padding: 8px;
  font-size: 12px;
  cursor: pointer;

}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    background-color: black;
    color: white;
}
.packingvouchertbody{
    margin-top: 5px;
}
/* .packingvouchertr{
    border-bottom: 1px solid #646B88;
} */


.packingdispachstatusdiv{ display: flex;                         /* Enables flexbox layout */
    justify-content: flex-end;             /* Aligns items to the right (end) of the container */
    width: 100%;                           /* Makes the element take up the full width of its parent */
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2); /* Adds a shadow at the top of the element */
    padding: 5px;                   
    border-top-left-radius: 15px;          /* Rounds the top-left corner */
    border-top-right-radius: 15px;         /* Rounds the top-right corner */
    height: auto;                          /* Allows the height to adjust based on the content */
    position: absolute;                    /* Positions the element absolutely in relation to its nearest positioned ancestor */
    bottom: 0;  }



/*-------------------- modal-------------- */
.modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 180px;
}

.modal_content {
    width: 90%;
    max-width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #000;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.modal_contentdiv {
    width: 90%;
    max-width: 400px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    color: #fff;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    text-align: center;
}

.input {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;
    height: 40px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border-radius: 3px;
    max-width: 383px;
    padding: 0 0 0 8px;
    line-height: 36px;
}

.inputcolor {
    width: 50%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    /* background: #fff; */
    /* color: #000; */
    /* border: 0.5px solid #000; */
    border-radius: 3px;
}

.inputpack {
    width: 10%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
}

.inputcolorpack {
    width: 20%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #fff;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 3px;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.modalBtn_div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    /* max-width: 90px; */
    /* margin: 32px auto 5px; */
}

.flexdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
}

.label {
    width: 50%;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
}

.fileimg {
    Width: 80px;
    Height: 50px;
    text-align: center;
    border: 1px solid #ffffff66;
}

.deleteicon {
    margin: 0;
    color: #F93E3E;
    font-weight: 300;
    font-size: 8.7524px;
    letter-spacing: 0.08em;
}

.deletediv {
    width: 46px;
    min-height: 46px;
    padding: 1px 0;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 2.1881px;
    /* border: 0.364683px solid #F93E3E; */
}

.voucherStatus {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding-right: 90px;
}

.status {
    color: #FF9B04;
    font-weight: 500;

}

.status_blue {
    color: #749AFF;
    font-weight: 500;

}

.statuscolor1 {
    color: #FF0D0D;
    font-weight: 500;

}

.statuscolor3 {
    color: #9747FF;
    font-weight: 500;

}
.statuscolor4{
    color: #00A052;
}
.statuscolor9{
    color: #FF9B04;
}
.statuscolor12{
    color: #FF7F50;
}
.orderStatus_Txt {
    font-size: 15px;
    padding-bottom: 4px;
    font-weight: 600;
}
.statuscolor8{
    color: red;

}

.packing_left {
    text-align: left;
}

.dot {
    font-size: 48px;
    margin-bottom: 13px;
    margin-right: 4px;
}

.status1 {
    color: red;
}

.status2 {
    color: green;
}

.status3 {
    color: #DDE100;
}

.closeicon {
    float: right;
}

.packers_float {
    float: right;
    margin-bottom: 20px;
}

/* --------------create-packers------------------ */

.packersheading {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin-top: 0;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.col {
    width: 44%;
}

.flex {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.packertext {
    width: 40%;
    font-size: 18px;
    color: darkgray;
}

.chooseProduct_heading {
    font-size: 18px;
    color: darkgray;
}

.createinputdark {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    color: #fff;
    background-color: #1E2022;
    border: 0.826924px solid #F3F3F3;
}

.createinput {
    width: 100%;
    height: 30px;
    outline: none;
    font-size: 15px;
    padding: 2px 5px;
    border-radius: 2px;
    border: 0.826924px solid #F3F3F3;
}

.margin {
    margin: 10px;
    padding-right: 16px;
}

.box {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 0.870311px solid darkgray;
    padding: 4px;
    width: 100%;
    max-width: 600px;
}

.products {
    margin: 5px;
    padding: 2px 8px;
    border-radius: 3.94832px;
    border: 1.74062px solid #1FFC33;
}

.radiobtn {
    accent-color: green;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.radiobtnred {
    accent-color: red;
    transform: scale(1.5);
    background-color: #fff;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.createbtn {
    width: 50%;
    display: flex;
    margin: 20px auto;
    justify-content: space-around;
}

.createpackers_btnone {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #F93E3E;
}

.createpackers_btnone:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #F93E3E;
    border: 1px solid #F93E3E;
}

.createpackers_btn {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #1FFC33;
}

.createpackers_btn:hover {
    width: 25%;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #1FFC33;
    border: 1px solid #1FFC33;
}

.itemInputs {
    display: inline;
    margin: 6px;
    border: 0.5px solid darkgray;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
    border-radius: 5px;
}

.itemInputs_selected {
    display: inline;
    margin: 6px;
    border: 0.5px solid #1FFC33;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}

/* --------------------------------------------------------------------------------------- */
.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

@media (max-width: 1400px) {
    .heading {
        font-size: 26px;
    }
}

.complain_header_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.complain_Sort_By {
    /* padding-right: 30px; */
}

.complain_sortBy_txt {
    font-size: 18px;
    padding-right: 8px;
}

.complain_sortBy_select {
    border: 2px solid #3A3A3A;
    height: 44px;
    width: 140px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
}

.complain_sortBy_select option {
    color: #fff;
    background-color: #000;
    text-align: left !important;
}

.complain_search_div {
    position: relative;
    width: 12%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 150px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.addAdjestmentBtn {
    color: #FFFFFF;
    line-height: 24px;

}

.margin_top {
    margin-top: 20px;
}

/* -----------------------------------------------orders------------------------------------------------------ */
.table_heading_div {
    box-sizing: border-box;
    height: 68px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    /* padding: 0 40px; */
}

.table_heading_20 {
    width: 20%;
    text-align: center;
}

.table_heading_15 {
    width: 15%;
    text-align: center;
}

.table_heading_10 {
    width: 10%;
    text-align: center;
}

.table_content_div {
    height: 56px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 12px 0;
    /* padding: 0 40px; */
    cursor: pointer;
}

.table_txt_20 {
    width: 20%;
    text-align: center;
}

.table_txt_15 {
    width: 15%;
    text-align: center;
}

.table_txt_10 {
    width: 10%;
    text-align: center;
}

.table_txt_green {
    color: #05FF00;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
    text-transform: capitalize;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}


.product_date {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-transform: uppercase;
    /* color: #9D9D9D; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    padding: 0 20px;
}

.itemCard_div {
    overflow-y: scroll;
    height: 420px;
    padding: 0 20px;
}

.itemCard {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #18191B;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemCard_light {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
}

.itemCard_left {
    display: flex;
    width: 40%;
}

.itemImage {
    width: 74px;
    height: 90px;
    margin: 0 12px;
}

.itemDetail_div {
    width: 100%;
    letter-spacing: 2px;
}

.itemDetail {
    display: flex;
}

.itemName_txt {
    width: 50%;
    font-size: 16px;
    font-weight: 400;
}

.itemName {
    width: 50%;
    /* padding-right: 8px; */
}

.itemCard_right {
    padding-right: 16px;
}

.modalFooter_div {
    width: 100%;
    height: 100px;
    background: #0f0f0f00;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalFooter_div_light {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}

.modalFooter_btn {
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

.modalFooter_GTotal {
    width: 45%;
    padding: 0 20px;
}

.borderBottom_div {
    border-bottom: 1px solid #6B6B6B;
    letter-spacing: 1px;
}

.total_div {
    display: flex;
    justify-content: space-between;
}

.total_rs {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.grandTotal {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    text-transform: capitalize;
    /* color: #FFFFFF;  */
}

.message {
    text-align: center;
    margin: 25px 0;
    font-size: 16px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-evenly;
    width: 300px;
    margin: auto;
    padding-bottom: 20px;
    /* gap: 1px; */
}


.dealer_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 214px;
    justify-content: center;
    padding: 1px 5px;
}

.dealer_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
    position: relative;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
    position: relative;
}

.heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
    /* max-height: calc(100vh - 400px);
    min-height: calc(100vh - 400px); */
}

.heightFix_div::-webkit-scrollbar {
    display: none;
}

.dispatchHeightFix {
    overflow-y: scroll;
    max-height: calc(100vh - 216px);
    min-height: calc(100vh - 216px);
}

.dispatchHeightFix::-webkit-scrollbar {
    display: none;
}

/* ------------------------------------Dispatch-Dashboard--------------------------------------------------- */
.DispatchMainDiv {
    color: #FFFFFF;
}

.Sort_By {
    padding-right: 30px;
}

.sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.sortBy_select {
    border: 1px solid #ffffff;
    height: 38px;
    width: 170px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.createPackerBtn {
    color: #FFFFFF;
}

.itemDescription_div {
    display: flex;
    /* width: 60%; */
    justify-content: space-between;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    /* border: 1px solid #1FFC33; */
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    /* border: 3px solid #00000045; */
    border-radius: 8px;
}

.view_Item_Image {
    width: 190px;
    height: 260px;
    display: flex;
    margin: 10px auto;
}

.demoImage_div {
    width: 86px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------------------------------------Create-Packers----------------------------------------------- */
.mainHeading {
    margin: 10px 0 16px 0;
    font-weight: 600;
    font-size: 30px;
}

.createPacker_container {
    background-color: #1E2022;
    border-radius: 10px;
    padding: 10px 0;
}

.radiobtn_div {
    display: flex;
    justify-content: left;
    align-items: center;
}

.radioBtn_heading {
    font-size: 18px;
    color: darkgray;
}

.packingVoucherDiv {
    /* width: 100%; */
    /* width: 90%; */
    /* margin: auto; */
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    gap: 50%;
}


.fixTableHead {
    overflow-y: auto;
    height: 38.5vh;
    color: #fff;
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead_packing {
    overflow-y: auto;
    height: 40.5vh;
    color: #fff;
    /* color: #fff; */
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead_packing thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    height: 46vh;
}

.fixTableHead_packing table {
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid #fff; */
    /* height: 46vh; */
}

.fixTableHeadtd {
    padding: 4px 8px;
    border: 1px solid #fff;
}

.h10 {
    height: 10px;
}

.h10 td {
    padding: 4px 8px;
    border: 1px solid rgba(128, 128, 128, 0.366);
    color: #000;
    margin: 20px;
    /* gap: 1px; */
    /* border-bottom: none!important; */
}

.brderTr tr td {
    border: none !important;
}

.fixTableHead thead th {
    background: #000;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #fff;
}

.fixTableHead_packing thead th {
    background: #E4E7EB;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #E4E7EB;
    color: #000;
}

.packing_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 200px;
    justify-content: center;
    padding: 1px 5px;
}

.packing_days_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
}

.packing_days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}


.displayflexDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enterPackets {
    width: 14%;
    height: 30px;
    outline: none;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 6px;
}

.checkbox {
    width: 15px;
    height: 15px;
}

.label_div {
    width: 44%;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 2px;
    text-align: center;
    border-radius: 5px;
    border: 0.729367px solid #1FFC33;
    line-height: 30px;
}

.packingImage {
    width: 80px;
    height: 50px;
    border: 0.441703px solid red;
    /* border: 0.441703px solid #D6D6D6; */
    padding: 3px;
}

.packingDeletediv {
    width: 44px;
    padding: 3px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    border: 0.364683px solid #F93E3E;
}

.packingModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.crossIcon {
    line-height: 42px;
    cursor: pointer;
}

.ordersNotiDiv {
    width: 100%;
    position: absolute;
    top: 0;
    color: #FFFFFF;
}

.ordersNoti {
    background: #2196F3;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 14px;
    margin: auto;
}

.disableBtn {
    color: gray;
    border: 1px solid gray;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.statusCard {
    box-sizing: border-box;
    width: 14%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 4px;
}
/* Wrapper around cards, keeps the layout responsive */
.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
    padding: 10px;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Card Styles */
.statusCard_div .order_filter {
    flex: 1 1 140px; /* This allows cards to grow and shrink with a minimum width */
    margin-right: 10px;
    margin-bottom: 10px; /* Adds space between filter items */
    padding: 8px;
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
}

/* Media Query for Tablets and Below */
@media (max-width: 768px) {
    .statusCard_div {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the left */
        padding: 15px;
    }

    .statusCard_div .order_filter {
        min-width: 100%; /* Full width on smaller screens */
        margin-bottom: 15px; /* Space between filter items */
        font-size: 14px; /* Adjust font size */
    }

    .statusCard_div .order_filter .dealerStatusCard {
        font-size: 12px; /* Make text smaller in the cards */
    }

    .statusCard_div span {
        font-size: 12px; /* Smaller font size for status count */
        margin-top: 10px;
    }

    .statusCard_div .statusTxt {
        font-size: 14px; /* Smaller font size for status text */
    }
}

/* Mobile-specific styles */
@media (max-width: 480px) {
    .statusCard_div {
        padding: 12px;
    }

    .statusCard_div .order_filter {
        min-width: 100%; /* Ensure filters take full width */
        font-size: 12px; /* Smaller font size for mobile */
    }

    .statusCard_div .statusTxt {
        font-size: 12px; /* Adjust font size for smaller screens */
    }

    .statusCard_div .order_filter .dealerStatusCard {
        font-size: 10px; /* Even smaller font size in cards */
    }
}
/* Card container, adjusts to screen size */
.order_filter {
  flex: 1 1 140px; /* Allows the card to grow and shrink with a minimum width of 140px */
  margin-right: 10px; /* Adds space between the cards */
  margin-bottom: 10px; /* Adds space between cards when they wrap */
  padding: 8px;
  box-sizing: border-box;
}

/* When the screen width is less than 768px (tablets and smaller devices) */
@media (max-width: 768px) {
  .order_filter {
    min-width: 100%; /* Make cards take full width on smaller screens */
    font-size: 14px; /* Adjust font size */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 15px; /* Space between cards */
  }

  .order_filter .dealerStatusCard {
    font-size: 12px; /* Adjust font size inside the card */
  }
}

/* When the screen width is less than 480px (mobiles) */
@media (max-width: 480px) {
  .order_filter {
    min-width: 100%; /* Cards take full width */
    font-size: 12px; /* Smaller font size */
    margin-bottom: 12px; /* Space between cards */
  }

  .order_filter .dealerStatusCard {
    font-size: 10px; /* Make text smaller inside the card */
  }
}


.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
    ;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusPacking {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
    height: 32px;
    padding: 0 2px;
}


.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.startEndDate_div {}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    margin-right: 16px;
    outline: none;
    text-align: center;
    border: 2px solid #3A3A3A;
}

.dragImage_div {
    /* display: flex;
    /* justify-content: center; 
    align-items: center;
    flex-direction: column; 
    /* border: 1px dashed #FFFFFF; */
    border-radius: 16px;
    /* padding: 16px 0 8px 0; */
    /* margin: 12px 0; */
    /* max-height: 300px; */
    /* border: 1px solid fuchsia; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}
.drag_txt1 {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
    color: #9C9797;
}

/* .border_OR_div {
    border: 1px solid #FFFFFF;
    width: 160px;
    margin: 0;
} */

.OR_css {
    position: absolute;
    margin-top: 36px;
    background: #1E2022;
    padding: 0 10px;
}

.browseFiles_btn {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}
.browseFiles_btn1 {
    margin: 20px auto;
    border-radius: 6px;
    background: #FFFFFF;
    /* background: red; */
    border: none;
    outline: none;
    line-height: 36px;
    padding: 0 20px;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
}

.checkBox_addImage {
    display: flex;
    justify-content: left;
    align-items: center;
}

.main_div {
    position: relative;
}

.dragAndDrop_div {
    height: 244px;
    position: absolute;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    margin-top: 12px;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
}

.source_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 250px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    margin: 0 5px;
    cursor: pointer;
}

.source_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.startEndDate_div {
    margin-left: 40px;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.order_heightFix_div {
    overflow-y: scroll;
    max-height: calc(100vh - 438px);
    min-height: calc(100vh - 438px);
}

.order_heightFix_div::-webkit-scrollbar {
    display: none;
}

.packing_image {
    background: transparent;
}
.packing_image1 {
    background: transparent;
    width: 100%;  /* Adjust width as needed (e.g., 100% to fill container, or fixed size like 500px) */
    height: auto; /* Keeps the aspect ratio, or set a specific height like 300px */
}

.view_packingImage {
    width: 120px;
    height: 46px;
    cursor: pointer;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    border-radius: 4px;
    /* border: 0.729367px solid #1FFC33; */
}

.packingStatus_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.packingImagefull {
    height: 100%;
}

.modal_close_button_div {
    display: flex;
    justify-content: right;
}

.modal_close_btn {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;

}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.manager_status {
    color: red !important;
}

.infoIcon {
    font-style: italic;
    border: 1px solid;
    border-radius: 50%;
    padding: 0 5px 0px 4px;
    font-size: 9px;
    color: red;
    text-transform: lowercase;
}

.editRequestButton {
    min-width: 216px;
}

.reqIssue {
    /* background-color: white; */
    /* border: 1px solid #FFFFFF; */
    width: 80%;
    padding: 7px 5px;
    border-radius: 5px;
    color: black;
    margin: 0px auto 40px;
    display: flex;
    text-align: center;
}
.mainCon{
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 60px);
    padding: 10px 10px 10px;
    /* border:2px soplid green; */
}


.packerdiv{align-items: center;}

.select_box {
    width: 100%;
    border-radius: 5px;
    border: none;
    text-align: left;
    outline: none;
    /* margin-left: 10px; */
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    font-size: 12px;
    font-weight: 400;
    display: flex;
  justify-content: flex-start;
  font-size: 16px;
}
.totalitemdiv{
    width: 100%;
}
.DropDown2div{
    width: 300px;
    margin-left: 10px;
}
.neworderbottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

/* Hide the .packingVoucherDiv div for screens between 350px and 700px */
@media (min-width: 1px) and (max-width: 700px) {
    .packingVoucherDiv {
      display: none; /* Hide the div within this screen width range */
    }
  }
  @media(min-width:1px)and(max-width:700px)
  {
    .dispatch_top_leftmob{
display: block;
    }
  }
.right_main {
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    padding: 0 15px;
}

.invoice_right_main {
    width: 100%;
    background-color: #1E2022;
    color: #ffffff;
    margin: 15px;
    border-radius: 10px;
    padding: 0 15px;
}

.invoice_dragDrop {
    width: 100%;
    opacity: 100;
    color: #ffffff;
    margin: 15px;
    border-radius: 10px;
    padding: 0 15px;
    border: 2px dashed #ccc;
    padding: 10px;
    overflow: auto;
}

.scroll_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.scroll_main_div::-webkit-scrollbar {
    display: none;
}

.dispatchDashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    /* line-height: 1.5rem; */
    justify-content: space-between;
    align-items: center;
}

.dispatchHeading {
    font-weight: 600;
    font-size: 30px;
    margin: 6px 0 0px 0;
}

.Invoices_Heading {
    font-weight: 600;
    font-size: 26px;
    margin: 6px 0 20px 0;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}


.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 310px;
    justify-content: center;
}

.recipt_tabs_div {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
    padding: 0 6px;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0px;
    cursor: pointer;
}

.dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

.startEndDate_div {
    margin-left: 40px;
}

.dashboard_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.dashboard_main_div::-webkit-scrollbar {
    display: none;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.Invices_statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px 0; */
    margin-bottom: 5px;
}

.statusCard {
    box-sizing: border-box;
    width: 11.8%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.danger_active {
    border: 3px solid #F32D3C;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusReupload {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

/* .statusBlue {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
} */

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
}

.dashboard_search_div {
    position: relative;
    width: 8%;
}

.dashboard_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    /* min-width: 270px; */
}

.dashboard_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 310px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.dashboard_sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.dispatchOrders_height {
    overflow-y: scroll;
    max-height: 528px;
    min-height: 528px;
}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}


.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.source_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 250px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    margin: 0 5px;
    cursor: pointer;
}

.source_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
    /* text-align: center; */
}


.table_txt_green {
    color: #05FF00;
    font-weight: 600;
}

.table_txt_orange {
    color: #FFC776;
    font-weight: 600;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

.checkbox {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 6px;
    padding-bottom: 5px;
}

.checkbox_empty {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 14px;
    padding-bottom: 5px;
}

.fixTableHead_packing {
    margin-top: 28px;
    overflow-y: auto;
    min-height: 18.5vh;
    color: #fff;
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead_packing thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead_packing table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #fff;
    /* height: 46vh; */
}

.fixTableHead_packing thead th {
    background: #000;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #fff;
}

.h10 {
    height: 10px;
}

.h10 td {
    padding: 4px 8px;
    border: 1px solid #fff;
    /* border-bottom: none!important; */
}

.itemDescription_div {
    display: flex;
    width: 60%;
    justify-content: space-between;
}

.d_flex {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin-top: 8px;
}

.addRecipt_div {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
}

.receipt_scroll {
    margin-top: 5px;
    max-height: 150px;
    overflow-y: scroll;
}

.receipt_scroll::-webkit-scrollbar {
    display: block !important;
    background-color: #fff;
    width: 7px;
}

.receipt_scroll::-webkit-scrollbar-thumb {
    background-color: #000;
}

.payment_detailsDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* height: 100%; */
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
}


.recipt_statusWidth {
    width: 210px;
}

.recipt_status_red {
    color: #F93E3E;
}

.recipt_status_green {
    color: #1FFC33;
}

.recipt_status_pending {
    color: yellow;
}

.subTotal_flex_col {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subTotal_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.shipingCharges_input {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    width: 100px;
    text-align: center;
    letter-spacing: 1px;
    outline: none;
}

.fixSize_input {
    width: 73px;
    text-align: right;
}

.adjustment {
    box-sizing: border-box;
    border: 0.5px dashed #F3F3F3;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #C2C2C2;
    background-color: transparent;
    text-align: center;
    outline: none;
    width: 138px;
}

.subTotal_txtRed {
    color: red;
    width: 80px;
    text-align: right;
}

.totalINR_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.orders_btn_div {
    display: flex;
    justify-content: space-around;
    width: 64%;
    /* margin: auto; */
    margin-top: 32px;
    padding-bottom: 20px;
}

.orders_btn_div1 {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 10px;
    max-height: 14vh;
    /* max-height: 20ch; */
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border: 1px solid red; */

}

.continer_all_feed {
    width: 100%;
    /* border: 1PX solid red; */
    display: flex;
}

.shippinginfo {
    /* position: absolute;      */
    /* bottom: 0; 
    left: 0;
    display: flex;
  
    /* gap: 10px;
    padding: 10px;   */
    /* border: 1px solid; */
    /* margin: auto; */
    /* margin-top: 32px; */
    /* padding-bottom: 20px; */

    /* justify-content: flex-end;            */
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
    /* border-top-left-radius: 20px;          
    border-top-right-radius: 20px;   */
    width: 25%;
    border-radius: 20px;


}

.btn11 {
    width: 100px;
}

.viewDetail_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadData_btn {
    /* min-width: 112px; */
    background-color: transparent;
    border: 1px solid #1FFC33;
    border-radius: 5px;
    padding: 10px 0;
    color: #1FFC33;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    line-height: 24px;
}

.main {
    width: 100%;
    /* border: 1PX solid; */
    height: calc(100vh - 65px);
    padding: 10px 10px;
    /* background-color: #05FF00; */
}

.mainDark {
    background-color: #232529;
}

.mainLight {
    background-color: #D9DCE5;
}


.mainConDark {
    background-color: #2C2E33;
    position: relative;
}

.mainConLight {
    background-color: #F8F9FC;
    position: relative;
}

/* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.order_top p {
    margin: 0;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.order_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.order_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 11%;
    align-content: "left";
    display: flex;
    align-content: flex-end;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter_dark {
    border: 1px solid #1B1D21;
}

.order_filter:hover {
    border-color: #A7A7A7;
}

.order_filter_dark:hover {
    border-color: #A7A7A7;
}

.order_filter_active {
    border-color: #A7A7A7;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.order_data_list_row,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    /* text-align: center; */
    /* cursor: pointer; */
    background-color: #fff;
    color: #000;
}

.order_data_list_row2,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    /* gap: 10px; */
    margin-bottom: 3px;
    /* text-align: center; */
    /* cursor: pointer; */
    background-color: #fff;
    color: #000;
}


.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

.order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
}

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

/* .order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
} */

/* .entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

} */

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

/* .voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
} */

.loader_con {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 36vh;
}

.entry_2 .inp_div {
    width: 100%;
    position: relative;
}

.item_inp {
    border: none;
    outline: none;
    background-color: transparent;
    color: #000;
}

.item_inp_dark {
    color: #FFFFFF;
}

.p_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.p_table_header,
.p_table_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.p_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.p_table_header_dark {
    color: #FFFFFF;
}

.p_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.p_table_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entry_2 {
    flex: 2;
}

.item_no {
    flex: 6;
}

/* .unit_dd {
    background-color: transparent;
    max-width: 80px;
    border-radius: 5px;
    color: #000;
    height: 30px;
    text-align: left;
}

.unit_dd_dark {
    color: #FFFFFF;
}

.unit_dd_option {
    background-color: #FFFFFF;
    text-align: left;
    border: none;
}

.unit_dd_option_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.unit_dd:focus {
    border: 1px solid #4164E3;
} */

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0.5rem;
}

/* .so_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 1rem;
}

.so_data_list_row_1,
.so_data_list_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #E4E7EB;
    text-align: left;
    width: 100%;
    color: #646B88;
    background-color: #fff;
}

.so_data_list_row_1 {
    padding: 0 10px;
    font-weight: 600;
    color: #646B88;
    background-color: transparent;
    border: none;
}

.so_data_list_row_dark {
    background-color: #1B1D21;
    color: #fafafa;
    border: none;
}

.so_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.col {
    flex: 1;
}

.so_data_list_container>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_top_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_top_right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.so_footer_top_right_1 {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 340px;
}

.so_footer_top_right_1_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_footer_top_right_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.so_footer_top_right_1>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_footer_top_right_1_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_footer_top_right_1_check>p {
    margin: 0;
    line-height: 1;
}

.so_footer_top_right_1_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_footer_top_right_1_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_footer_top_right_1_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

/* .so_shipping {
    display: flex;
    align-items: center;
}

.so_shipping input {
    max-width: 60px;
    border: 1px solid #646B88;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    padding: 0.1rem 0.5rem;
    background-color: transparent;
} */

.so_footer_top_right_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

/* .cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
} */

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.so_inp {
    background-color: transparent;
    border: 1px solid #E4E7EB;
    border-radius: 6px;
    outline: none;
    color: #646B88;
    padding: 0.2rem 1rem;
    max-width: 150px;
    min-width: 50px;
}

.so_inp_dark {
    color: #fff;
    border-color: #646B88;
}

.right>div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.right>div>p {
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: #646B88;
    font-weight: 500;
}

.right>p {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}

.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.element_2 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.element_2 p {
    margin: 0;
    font-size: 14px;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
    /* margin-bottom: 20px; */
}

.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item */
/* .si_inp {
    position: relative;
} */

.search_item {
    position: absolute;
    top: 150%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    color: #000;
    padding: 0.3rem 0;
    width: 100%;
    z-index: 2;
}

.TopTabBar {
    display: flex;
}

.invoiceheading {
    padding: 2px;
    font-weight: 600;
    font-size: 18px;
}

.carddiv {
    display: flex;
    gap: 10px;
    padding: 10px;
    /* padding-top: 10px; */
    /* border: 1px solid; */
    background-color: white;
    border-radius: 20px;

}

.carddivmain {
    padding-top: 10px;
    background-color: #007BFF;
    border-radius: 20px;
}

.dropdown {
    display: flex;
}

.sendbtn {
    width: 150px;
    padding: 5px;
}

.container3 {
    display: flex;
    justify-content: space-around;
}

.searchcontainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
}

.dropdowncontainer {
    display: flex;
    gap: 10px;
    z-index: 999;
}

.search {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
    /* cursor: pointer; */
    /* border: 1px solid; */

}

.cl_checkbox {
    position: relative;
    display: inline-block;
}

/* Input */
.cl_checkbox>input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.5s, transform 0.2s;
}

/* Span */
.cl_checkbox>span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Box */
.cl_checkbox>span::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    margin: 2px 11px 3px 0px;
    border: solid 2px;
    /* Safari */
    border-color: #646B88;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.cl_checkbox>span::after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.cl_checkbox>input:checked,
.cl_checkbox>input:indeterminate {
    background-color: #0065FF;
}

.cl_checkbox>input:checked+span::before,
.cl_checkbox>input:indeterminate+span::before {
    border-color: #0065FF;
    background-color: #0065FF;
}

.cl_checkbox>input:checked+span::after,
.cl_checkbox>input:indeterminate+span::after {
    border-color: #fff;
}

.cl_checkbox>input:indeterminate+span::after {
    border-left: none;
    transform: translate(4px, 3px);
}

/* Hover, Focus */
.cl_checkbox:hover>input {
    opacity: 0.04;
}

.cl_checkbox>input:focus {
    opacity: 0.12;
}

.cl_checkbox:hover>input:focus {
    opacity: 0.16;
}

/* Active */
.cl_checkbox>input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.cl_checkbox>input:active+span::before {
    border-color: #0065FF;
}

.cl_checkbox>input:checked:active+span::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.cl_checkbox>input:disabled {
    opacity: 0;
}

.cl_checkbox>input:disabled+span {
    color: rgba(0, 0, 0, 0.38);
    cursor: initial;
}

.cl_checkbox>input:disabled+span::before {
    border-color: currentColor;
}

.cl_checkbox>input:checked:disabled+span::before,
.cl_checkbox>input:indeterminate:disabled+span::before {
    border-color: transparent;
    background-color: currentColor;
}

.packingvouchertbody {
    margin-top: 5px;

    /* position: relative; */
}

.packingvouchertbody1 {
    max-height: 10px;
    overflow-y: scroll;
    border: 1px solid;
}

.packingvouchertbody2 {
    border: 1px solid;
    background-color: gold;
}



.scrollable-tbody {
    display: block;
    max-height: 100px;
    /* Set the maximum height for the scrollable tbody */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
    /* Disable horizontal scrolling */
}

.scrollable-tbody tr {
    display: table;
    /* Retain table row structure */
    width: 100%;
    /* Ensure rows take up full width */
    table-layout: fixed;
    /* Keep consistent column widths */
}

.darkMode {
    background-color: #1B1D21;
    color: #fff;
}

.lightMode {
    background-color: #fff;
    color: #000;
}

.packingvouchertd {
    padding: 8px;
    font-size: 12px;
    cursor: pointer;

}

.packingvouchertbody {
    margin-top: 5px;
}

.packingvoucherth {

    position: sticky;
    /* Makes the element stick to the viewport during scrolling */
    top: 0;
    /* The element will stick to the top of its container */
    z-index: 1;
    /* Sets the stack order of the element */

    padding: 8px;
}

.packingvoucherth22 {

    position: sticky;
    /* Makes the element stick to the viewport during scrolling */
    top: 0;
    /* The element will stick to the top of its container */
    /* z-index: 1; */
    /* Sets the stack order of the element */

    padding: 3px;
}

.rowBorder {
    border-bottom: 2px solid #646B88;
    border: 2px solid #ddd;
}

.darkModeHeader {
    background-color: #232529;
    color: #FFFFFF;
    /* color: #fff; */
}

.lightModeHeader {
    background-color: #D9DCE5;
    color: #646B88;
}

.packingvouchertable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.packingvouchertable1 {
    width: 25%;
    border-collapse: separate;
    border-spacing: 0 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid blue;
    /* max-height: 50px; */
}


.packingvouchertablediv {
    width: 100%;
    /* align-self: center; */
    /* padding: 10px; */
    height: 500px;
    overflow-y: auto;
    /* cursor: pointer; */
    border-radius: 5px;
    padding-top: 7px;
    /* position: relative; */
}

.packingvouchertr {
    border-bottom: 1px solid #646B88;

}

.packingvouchertr23 {
    border-bottom: 1px solid #646B88;
    border-spacing: 0 10px;
}

.tablemaincon {
    margin-right: 20px;
}

.paginationdiv {
    bottom: 0;
    /* width: 100%;  */
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #FFFFFF;
    /* Default light mode */
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
    /* Default light mode */
}

.page1 {
    width: 100%;
    border: 1px solid;
}

.page2 {
    width: 100%;
    border: 1px solid;
}

.mgx_pagination {
    /* height: 5vh; */
    /* width: calc(100%); */
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0px;
    /* left: 50%; */
    /* transform: translate(-50%); */
    width: 100%;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    border-radius: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);

    /* margin: 4px; */
    /* border: 1px solid; */
}

/* .paginationdiv.dark-mode {
    background-color: #1B1D21;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  }
  .paginationdiv.light-mode {
    background-color: #1B1D21;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  } */
.inputboxes {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.inputboxes1 {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding-top: 15px;
}

.input1 {
    width: 23vw;
}

.input2 {
    width: 17vw;
}

.input3 {
    width: 46vw;
}

.table_txt_green {
    /* color: #3F51B5; */
    color: #00A052;
    font-weight: 500;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 500;
}

.bottombtncon {
    display: flex;
    /* justify-content: flex-end; */
justify-content: flex-end;    /* flex: 1; */
    gap: 5px;
    /* padding-top: 70px; */
}

.receipts {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.receipts_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 0 10px;
}

.receipts_top p {
    margin: 0;
}

.receipts_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.receipts_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.receipts_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.receipts_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.receipts_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.receipts_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.receipts_filter_dark {
    border: 1px solid #1B1D21;
}

.receipts_filter:hover {
    border-color: #4164E3;
}

.receipts_filter_dark:hover {
    border-color: #656565;
}

.receipts_filter_active_dark {
    border-color: #656565;
}

.receipts_filter_active {
    border-color: #4164E3;
}

.receipts_bottom {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 10px;
    max-height: calc(100vh - 340px);
}

.receipts_bottom_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receipts_bottom_filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_data {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.receipts_data_header,
.receipts_data_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    /* z-index: 1; */
}

.receipts_data_row {
    position: relative;
}

.receipts_data_header {
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
}

.receipts_data_header_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.receipts_data_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.receipts_data_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    padding-bottom: 4rem;
}
/* 
.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.options {
    transition: 0.2s ease-in;
    position: absolute;
    right: -5px;
}

.desc {
    color: #000000;
    font-size: 12px;
    width: 100%;
    padding: 0 0.5rem;
}

/* .options:hover {
    transform: scale(0.8);
} */

.options_menu {
    background-color: #fff;
    color: #000000;
    list-style: none;
    width: 6rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px #00000040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;
    /* right: 1rem; */
    z-index: 100;
}

.options_menu_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.options_menu_li {
    font-size: 12px;
    width: 100%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.options_menu_li:hover {
    background-color: #e4e7eb;
}

.options_menu_li_dark:hover {
    background-color: #333;
}

/* cancel receipt modal  */
.cancelled_receipt_modal,
.cancel_receipt_modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.cancel_receipt_modal>p {
    margin: 0;
}

.cancel_receipt_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.cancel_receipt_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.cancel_receipt_modal>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 0.5rem;
    border-radius: 5px;
    color: #1b1d21;
    font-weight: 400;
}

.cancel_receipt_modal>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.cancel_receipt_modal>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cancelled_receipt_modal {
    width: 370px;
}

.cancelled_receipt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.cancelled_receipt>p:nth-of-type(1) {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    align-self: flex-start;
    margin: 0;
}

.cancelled_receipt>p:nth-of-type(2) {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}


/* .receipts {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.receipts_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 0 10px;
}

.receipts_top p {
    margin: 0;
}

.receipts_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.receipts_top_left_path_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}

.receipts_go_back_text {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding-top: 0.21rem;
    color: #0100E4;
}

.receipts_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.receipts_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.receipts_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
}

.receipts_filter_dark {
    border: 1px solid #1B1D21;
}

.receipts_filter:hover {
    border-color: #4164E3;
}

.receipts_filter_dark:hover {
    border-color: #FFFFFF;
}

.receipts_filter_active {
    border-color: #4164E3;
}

.receipts_bottom {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 10px;
    max-height: calc(100vh - 340px);
}

.receipts_bottom_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.receipts_bottom_filters {
    display: flex;
    align-items: center;
    gap: 10px;
}

.receipts_data {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.receipts_data_header,
.receipts_data_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.receipts_data_row {
    position: relative;
}

.receipts_data_header {
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
}

.receipts_data_header_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.receipts_data_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.receipts_data_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
}

.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.options {
    transition: 0.2s ease-in;
    position: absolute;
    right: -5px;
}

.options:hover {
    transform: scale(0.8);
}

.options_menu {
    background-color: #fff;
    color: #000000;
    list-style: none;
    width: 6rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px #00000040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;
    right: 1rem;
    z-index: 1000;
}

.options_menu_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.options_menu_li {
    font-size: 12px;
    width: 100%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.options_menu_li:hover {
    background-color: #e4e7eb;
}

.options_menu_li_dark:hover {
    background-color: #333;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
} */
.fixedHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    /* Ensures it stays above other elements */
    background-color: inherit;
    /* Matches table's background color */
}

/* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid; */
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter:hover {
    border-color: #A7A7A7;
}

.order_filter_dark {
    border-color: #1B1D21;
}

.order_filter_dark:hover {
    border-color: #656565;
}

.order_filter_active_dark {
    border-color: #656565;
}

.order_filter_active {
    border-color: #4164E3;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

/* .order_data_list_row,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    background-color: #fff;
    color: #000;
} */

.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

/* .order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
} */

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

.order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    height: calc(100vh - 410px);
}
.order_data_list_container2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    /* border: 1px solid; */
    height: calc(100vh - 200px);
    /* max-height: 790PX;
    min-height: 400PX; */
}
.order_data_list_container12{
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    /* border: 1px solid green; */
    height: calc(100vh - 429px);
    /* max-height: 790PX;
    min-height: 400PX; */
}
.entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.entry1 {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 50PX;
}

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.loader_con {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 36vh;
}

.inp_div {
    position: relative;
    /* background-color: lightblue; */
}

.item_inp {
    border: none;
    outline: none;
    /* background-color: lightcoral; */
    background-color: transparent;
    color: #000;
    font-size: 12px;
    width: auto;
}

.item_inp_dark {
    color: #FFFFFF;
}

.desc,
.desc_mob {
    font-size: 12px;
    width: 100%;
    padding: 0 0.5rem;
}

.p_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.p_table_header_con {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.p_table_header,
.p_table_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.p_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.p_table_header_dark {
    color: #FFFFFF;
}

.p_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.p_table_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entry_2 {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item_no {
    flex: 6;
    overflow: visible;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0.5rem;
}

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_right {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.right_btns {
    display: flex;
    gap: 0.5rem;
}

.so_amount_info {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* padding-right: 30px; */
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.2rem;
    width: 340px;
}

.so_amount_info_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_amount_info>div {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
}

.so_amount_info>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_check>p {
    margin: 0;
    line-height: 1;
}

.so_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}

.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.element_2 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.element_2 p {
    margin: 0;
    font-size: 14px;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}

.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item  */
.search_item {
    position: absolute;
    top: 150%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    color: #000;
    padding: 0.3rem 0;
    z-index: 2;
    width: 480px;
}

.search_item_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.search_item_header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 10px;
    border-bottom: 1px solid #E4E7EB;
}

.search_item_header>p {
    margin: 0;
    cursor: pointer;
}

.search_item_header>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.item_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.i_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.i_table_header_con {
    display: flex;
    align-items: center;
}

.i_table_row,
.i_table_header {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    width: 100%;
}

.i_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.i_table_header_dark {
    color: #FFFFFF;
}

.i_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.i_table_list {
    display: flex;
    flex-direction: column;
    max-height: 160px;
    overflow: hidden;
    overflow-y: auto;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
    color: #FFFFFF;
}

/* shipping charges modal  */
.shipping_charges_modal {
    padding: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 0px 4px 50px 0px #00000026;
}

.shipping_charges_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.shipping_charges_modal>img {
    margin: 1rem auto;
}

.shipping_charges_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

.shipping_charges_modal>div {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
}

.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* refund receipt */
.refund_receipt {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
}

.add_reciept_modal>.refund_receipt>p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #646b88;
}

.refund_receipt>.textarea {
    font-size: 12px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
}

.refund_receipt>.textarea::placeholder {
    color: #9f9f9f;
}

.refund_receipt>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

/* iocn action text */
.icon_action_text {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.icon_action_text>svg {
    height: 18px;
    width: 18px;
}

.icon_action_text>p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1;
}

/* responsive */
.order_dd_mob {
    display: none;
    width: 8rem;
}

.show_760px {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #E4E7EB;
}

.show_760px_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
    border-color: #1B1D21;
}

.show_760px_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_1>p {
    margin: 0;
    font-size: 12px;
    color: #646B88;
}

.show_760px_1>span {
    font-weight: 700;
}

.show_760px_2 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.show_760px_2>div>p {
    margin: 0;
    font-size: 12px;
}

.show_760px_2,
.show_760px_3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_3 {
    gap: 10%;
}

.show_760px_3>.element {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.show_760px>.show_760px_3>.element>div {
    background-color: #F8F9FC;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
}

.show_760px_3>.element>p,
.show_760px_3>.element>div>p {
    margin: 0;
}

.show_760px_3>.element>div>p {
    font-size: 12px;
}

.show_760px_3>.element>p {
    font-size: 12px;
}

.show_760px_4 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    font-size: 12px;
}

.show_1150px,
.show_760px {
    display: none;
}

@media (width<1350px) {
    .so_footer {
        padding: 4px;
    }

    .so_footer_left {
        gap: 1px;
    }

    .so_receipt_data {
        width: 340px;
    }

    .add_receipt_btn {
        padding: 0.1rem;
    }

    .so_footer_right,
    .right_btns {
        gap: 2px;
    }

    .so_amount_info {
        width: 280px;
    }
}

@media (width<1150px) {
    .right_btns {
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
    }

    .so_footer_right {
        gap: 0.5rem;
    }

    .right_btns>.hide_1150px {
        display: none;
    }

    .right_btns>.show_1150px {
        display: block;
    }
}

@media (width<1200px) {
    .order_sf {
        display: none;
    }

    .order_dd_mob {
        display: block;
    }
}

@media (width<890px) {
    .so_form {
        gap: 10px;
        flex-direction: column;
    }

    .so_footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .so_footer_left {
        width: 100%;
        justify-content: center;
    }

    .so_footer_right {
        width: 100%;
        justify-content: center;
    }

    .p_table_header,
    .hide_760px {
        display: none;
    }

    .show_760px {
        display: flex;
    }

    .so_instc {
        display: none;
    }

    .search_item {
        left: -7%;
        top: -1000%;
    }

    .i_table_list {
        max-height: 100px;
    }

    .dealer_address_modal {
        max-width: 340px;
    }

    .address_Pop_Up>img {
        height: 10rem;
    }

    .dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
        font-size: 14px;
        width: 90%;
        font-weight: 400;
    }

    .dealer_address_modal>.address_Pop_Up>p {
        font-weight: 300;
        font-size: 12px;
    }

    .btn_text {
        display: none;
    }

    .so_scroll_wrapper {
        overflow: auto;
        padding-bottom: 0;
    }

    .desc {
        display: none;
    }
}

@media (width<700px) {
    .order {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        background: #f8f9fc;
        overflow: auto;
    }

    /* .order_top_left {
        display: none;
    } */

    .order_right {
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
    }

    .so_footer {
        position: fixed;
    }
}

@media (width<400px) {
    .so_footer_right {
        flex-direction: column;
        align-items: center;
    }

    .right_btns {
        flex-direction: row;
        gap: 1rem;
    }

    .so_amount_info {
        width: 100%;
    }

    .next_btn {
        width: 100%;
    }
}

.tablecontainern {
    width: 100%;
    margin: 20px auto;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
}

.tablewrappern {
    max-height: 50vh;
    overflow-y: auto;
    /* border-top: 2px solid #ddd; */
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    background: #f4f4f4;
    position: sticky;
    top: 0;
    z-index: 10;
}

th,
td {
    font-size: 12px;
    padding: 8px;
    text-align: left;
    border-bottom: 5px solid #F8F9FC;
}

/* all orders */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.order_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.right,
.order_right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order_filters {
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px 10px 10px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
}

.order_filter {
    width: 11%;
    height: 110px;
    min-width: 100px;
    border-radius: 15px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.order_filter:hover {
    border-color:#A7A7A7;
}

.order_filter_dark {
    border-color: #1B1D21;
}

.order_filter_dark:hover {
    border-color: #656565;
}

.order_filter_active_dark {
    border-color: #656565;
}

.order_filter_active {
    border-color: #A7A7A7;
}

.order_data {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: calc(100vh - 340px);
}

.order_data_filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order_data_filters>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order_data_list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
}

.order_data_list_row,
.order_data_list_row_header {
    letter-spacing: 0.03em;
    display: flex;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    /* cursor: pointer; */
    background-color: #fff;
    color: #000;
}

.order_data_list_row_dark {
    background-color: #1B1D21;
    color: #FAFAFA;
}

.order_data_list_row_header {
    font-weight: 500;
    cursor: default;
    background-color: #D9DCE5;
    color: #646B88;
}

.order_data_list_row_header_dark {
    background-color: #232529;
    color: #FAFAFA;
}

/* .order_data_list_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 40vh;
    overflow-y: auto;
} */

/* .entry {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.pagination {
    height: 5vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 4px 0px #0000001A;
    border-radius: 10px 10px 0 0;
}

.pagination .go_to_page {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.pagination .pages {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

/* Sale Order */
.order {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.so_scroll_wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 12rem;
}

.so_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.so_form_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.so_form_1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.voucher_det {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
}

.loader_con {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 36vh;
}

.inp_div {
    position: relative;
    /* background-color: lightblue; */
}

.item_inp {
    border: none;
    outline: none;
    /* background-color: lightcoral; */
    background-color: transparent;
    color: #000;
    font-size: 12px;
    width: auto;
}

.item_inp_dark {
    color: #FFFFFF;
}

.desc,
.desc_mob {
    font-size: 12px;
    width: 100%;
    padding: 0 0.5rem;
}

.p_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.p_table_header_con {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.p_table_header,
.p_table_row {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.p_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.p_table_header_dark {
    color: #FFFFFF;
}

.p_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.p_table_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.entry_2 {
    flex: 2;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item_no {
    flex: 6;
    overflow: visible;
}

.cricle_btn {
    align-self: flex-end;
    border: 2px solid #4164E3;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0.5rem;
}

.so_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001A;
    padding: 10px;
    background: linear-gradient(93.25deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.so_footer_dark {
    background: linear-gradient(93.25deg, rgba(44, 46, 51, 0.9) 0%, rgba(44, 46, 51, 1) 100%);
}

.so_footer_left {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    margin-right: auto;
}

.so_receipt_data {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    box-shadow: 0px 2px 20px 0px #0000001A;
    border-radius: 15px;
    padding: 0.5rem;
    max-height: 150px;
    width: 360px;
}

.so_receipt_data_dark {
    background-color: #1B1D21;
}

.scroll_con {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
}

.so_receipt_data_header,
.so_receipt_data_entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    list-style: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.so_receipt_data_header_dark {
    background-color: #2c2e33 !important;
    color: #ffffff !important;
}

.so_receipt_data_entry_dark {
    background-color: #232329 !important;
    color: #ffffff;
}

.so_receipt_data>div:nth-child(1) {
    background-color: #D9DCE5;
    color: #646B88;
    font-weight: 600;
    line-height: 1;
}

.scroll_con>div {
    background-color: #FFFFFF;
    line-height: 1;
    font-size: 12px;
}

.add_receipt_btn {
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    padding: 0.25rem;
}

.so_footer_right {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    margin-left: auto;
}

.right_btns {
    display: flex;
    gap: 0.5rem;
}

.so_amount_info {
    background-color: #F8F9FC;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 0px #0000001A;
    padding: 0.5rem;
    width: 340px;
}

.so_amount_info_dark {
    background-color: #1B1D21;
    color: #fff;
}

.so_amount_info>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.so_amount_info>div>p {
    margin: 0;
    line-height: 1;
    font-size: 12px;
}

.so_check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
}

.so_check>p {
    margin: 0;
    line-height: 1;
}

.so_check>input[type="checkbox"] {
    cursor: pointer;
}

.so_check>input[type="checkbox"]:focus {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

.so_check>input[type="checkbox"]:disabled {
    cursor: not-allowed;
}

.so_instc {
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.so_instc>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

/* add reciept modal */
.add_reciept_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #F8F9FC;
    color: #fff;
    border-radius: 15px;
    gap: 0.5rem;
    min-width: 348px;
}

.add_reciept_modal>div>p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.add_reciept_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* file upload */
.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
    border-radius: 10px;
    text-align: center;
    border: 1px dashed #646B88;
    background-color: #fff;
    margin: 0.2rem 0;
    z-index: 2;
}

.file_upload_text {
    margin-top: auto;
    margin-bottom: auto;
}

.file_upload_text>p:nth-of-type(1) {
    color: #646B88;
    margin: 0;
    font-size: 12px;
}

.file_upload_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 10px;
    color: #1B1D21;
}

.file_upload_text>p>span {
    color: #4164E3;
    text-decoration: underline;
    cursor: pointer;
}

.file_upload_footer {
    z-index: 1;
    margin-top: -1.2rem;
    background-color: #D9DCE5;
    border-radius: 0 0 10px 10px;
    padding: 0.5rem 1rem 0.4rem 1rem;
}

.file_upload_footer>div>span {
    font-size: 10px;
    color: #646B88;
}

.file_preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* share link modal */
.share_link_modal {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FC;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px 0px #00000026;
    max-width: 400px;
}

.share_link_modal>.header_text>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #1B1D21;
}

.share_link_modal>.header_text>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.6rem;
}

.share_link_modal>.icons_tray {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.copy_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.share_link_modal>.icons_tray>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.icon,
.share_link_modal>.icons_tray>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.share_link_modal>.icons_tray>div>p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}

.share_link_modal>.send_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0rem;
    margin: 0.6rem 0;
}

.send_link>div:nth-of-type(1) {
    display: flex;
    width: 80%;
}

.pay_link {
    width: 100%;
}

.pay_link>input,
.send_link>div>input {
    outline: none;
    padding: 0.7rem;
    border-radius: 5px;
    border: 1px solid #E4E7EB;
    color: #000000;
    background-color: #fff;
    font-size: 12px;
}

.send_link>div>input:nth-of-type(1) {
    width: 16%;
    border-radius: 5px 0 0 5px;
    border-right: none;
    padding: 0;
    padding-left: 0.7rem;
}

.send_link>div>input:nth-of-type(2) {
    width: 80%;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding: 0.7rem 0;
}

/* add new item modal */
.add_new_item_modal {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    background-color: #F8F9FC;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 400px;
}

.add_new_item_modal>div>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #646B88;
}

.add_new_item_modal>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.element_2 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.element_2 p {
    margin: 0;
    font-size: 14px;
}

/* search dealer */
.search_dealer {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 100%;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    top: 100%;
    z-index: 1;
    border: none;
    outline: none;
    padding-bottom: 0.5rem;
}

.search_dealer_dark {
    background-color: #232529;
    color: #fff;
}

.search_dealer>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0.5rem;
}

.search_dealer>div>p {
    margin: 0.5rem 0;
    cursor: pointer;
}

.search_dealer>div>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.search_dealer>.search_list {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    max-height: 6rem;
}

.search_list_item {
    width: 100%;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid ridge;
}

/* search item  */
.search_item {
    position: absolute;
    top: 150%;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    font-size: 12px;
    border: none;
    outline: none;
    color: #000;
    padding: 0.3rem 0;
    z-index: 2;
    width: 480px;
}

.search_item_dark {
    background-color: #232529;
    color: #FFFFFF;
}

.search_item_header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 10px;
    border-bottom: 1px solid #E4E7EB;
}

.search_item_header>p {
    margin: 0;
    cursor: pointer;
}

.search_item_header>p:nth-of-type(2) {
    text-decoration: underline;
    cursor: pointer;
}

.item_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
}

.i_table {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.i_table_header_con {
    display: flex;
    align-items: center;
}

.i_table_row,
.i_table_header {
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    background-color: #fff;
    color: #000;
    padding: 5px 10px;
    width: 100%;
}

.i_table_header {
    background-color: transparent;
    color: #646B88;
    font-weight: 600;
}

.i_table_header_dark {
    color: #FFFFFF;
}

.i_table_row_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
}

.i_table_list {
    display: flex;
    flex-direction: column;
    max-height: 160px;
    overflow: hidden;
    overflow-y: auto;
}

.selected_row_dark:hover {
    background-color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row {
    background-color: transparent;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected_row:hover {
    background-color: #e4e7eb;
}

.selected_row_dark {
    background-color: transparent;
    color: #FFFFFF;
}

.selected_row_dark:hover {
    background-color: #333333;
}

.focused_row {
    background-color: #e4e7eb;
}

.focused_row_dark {
    background-color: #333333;
    color: #FFFFFF;
}

/* shipping charges modal  */
.shipping_charges_modal {
    padding: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    box-shadow: 0px 4px 50px 0px #00000026;
}

.shipping_charges_modal>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.shipping_charges_modal>img {
    margin: 1rem auto;
}

.shipping_charges_modal>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

.shipping_charges_modal>div {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
}

.btn_text {
    text-align: center;
    margin: 0;
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}

.element>.btn_div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* dealer address modal */
.dealer_address_modal {
    background-color: #f8f9fc;
    color: #000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    width: 380px;
    overflow: hidden;
}

.dealer_address_modal>p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
}

.update_location_form>.element,
.address_Pop_Up>.element,
.dealer_address_modal>.element {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: 90%;
}

.dealer_address_modal>.address_Pop_Up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.dealer_address_modal>.address_Pop_Up>p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
}

.dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
    font-size: 16px;
    width: 80%;
}

.transition {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.transition_active {
    opacity: 1;
    transform: translateY(0);
}

.update_location_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modal_txt_container {
    width: 100%;
    margin-top: 0.2rem;
    height: 30px;
}

.modal_txt_container>p {
    text-align: left;
    width: 100%;
    color: #646B88;
    font-size: 14px;
    margin: 0;
    line-height: 1;
}

/* order success modal */
.order_success_modal {
    background-color: #f8f9fc;
    color: #000000;
    box-shadow: 0px 4px 50px 0px #00000026;
    border-radius: 15px;
    padding: 1rem;
    max-width: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.order_success_modal>p:nth-of-type(1) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    width: 100%;
}

.order_success_modal>img {
    margin: 0 auto;
}

.order_success_modal>p:nth-of-type(2) {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}

.order_success_modal>div {
    display: flex;
    gap: 0.5rem;
    width: 80%;
}

/* used at every dark modal imp */
.dealer_address_modal_dark {
    background-color: #232529;
    color: #FFFFFF;
}

/* new sale order */
.req_for_cancel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #F8F9FC;
    border-radius: 15px;
    padding: 1rem;
}

.req_for_cancel>p {
    margin: 0;
}

.req_for_cancel>p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.req_for_cancel>p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.req_for_cancel>.textarea {
    font-size: 14px;
    border: none;
    outline: none;
    border: 1.4px solid #E4E7EB;
    padding: 0.5rem;
    border-radius: 5px;
}

.req_for_cancel>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

.req_for_cancel>div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* refund receipt */
.refund_receipt {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.3rem;
}

.add_reciept_modal>.refund_receipt>p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #646b88;
}

.refund_receipt>.textarea {
    font-size: 12px;
    border: none;
    outline: none;
    border: 1.4px solid #e4e7eb;
    padding: 8px;
    border-radius: 5px;
    width: 100%;
}

.refund_receipt>.textarea::placeholder {
    color: #9f9f9f;
}

.refund_receipt>.textarea_dark {
    background-color: #1B1D21;
    border-color: #1B1D21;
    color: #FFFFFF;
}

/* iocn action text */
.icon_action_text {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.icon_action_text>svg {
    height: 18px;
    width: 18px;
}

.icon_action_text>p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1;
}

/* responsive */
.order_dd_mob {
    display: none;
    width: 8rem;
}

.show_760px {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #E4E7EB;
}

.show_760px_dark {
    background-color: #1B1D21;
    color: #FFFFFF;
    border-color: #1B1D21;
}

.show_760px_1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_1>p {
    margin: 0;
    font-size: 12px;
    color: #646B88;
}

.show_760px_1>span {
    font-weight: 700;
}

.show_760px_2 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.show_760px_2>div>p {
    margin: 0;
    font-size: 12px;
}

.show_760px_2,
.show_760px_3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_760px_3 {
    gap: 10%;
}

.show_760px_3>.element {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.show_760px>.show_760px_3>.element>div {
    background-color: #F8F9FC;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
}

.show_760px_3>.element>p,
.show_760px_3>.element>div>p {
    margin: 0;
}

.show_760px_3>.element>div>p {
    font-size: 12px;
}

.show_760px_3>.element>p {
    font-size: 12px;
}

.show_760px_4 {
    background-color: #F8F9FC;
    border-radius: 10px;
    padding: 8px;
    font-size: 12px;
}

.show_1150px,
.show_760px {
    display: none;
}

@media (width<1350px) {
    .so_footer {
        padding: 4px;
    }

    .so_footer_left {
        gap: 1px;
    }

    .so_receipt_data {
        width: 340px;
    }

    .add_receipt_btn {
        padding: 0.1rem;
    }

    .so_footer_right,
    .right_btns {
        gap: 2px;
    }

    .so_amount_info {
        width: 280px;
    }
}

@media (width<1150px) {
    .right_btns {
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
    }

    .so_footer_right {
        gap: 0.5rem;
    }

    .right_btns>.hide_1150px {
        display: none;
    }

    .right_btns>.show_1150px {
        display: block;
    }
}

@media (width<1200px) {
    .order_sf {
        display: none;
    }

    .order_dd_mob {
        display: block;
    }
}

@media (width<890px) {
    .so_form {
        gap: 10px;
        flex-direction: column;
    }

    .so_footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }

    .so_footer_left {
        width: 100%;
        justify-content: center;
    }

    .so_footer_right {
        width: 100%;
        justify-content: center;
    }

    .p_table_header,
    .hide_760px {
        display: none;
    }

    .show_760px {
        display: flex;
    }

    .so_instc {
        display: none;
    }

    .search_item {
        left: -7%;
        top: -1000%;
    }

    .i_table_list {
        max-height: 100px;
    }

    .dealer_address_modal {
        max-width: 340px;
    }

    .address_Pop_Up>img {
        height: 10rem;
    }

    .dealer_address_modal>.address_Pop_Up>p:nth-of-type(1) {
        font-size: 14px;
        width: 90%;
        font-weight: 400;
    }

    .dealer_address_modal>.address_Pop_Up>p {
        font-weight: 300;
        font-size: 12px;
    }

    .btn_text {
        display: none;
    }

    .so_scroll_wrapper {
        overflow: auto;
        padding-bottom: 0;
    }

    .desc {
        display: none;
    }
}

@media (width<700px) {
    .order {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        background: #f8f9fc;
        overflow: auto;
    }

    .order_top_left {
        display: none;
    }

    .order_right {
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
    }

    .so_footer {
        position: fixed;
    }
}

@media (width<400px) {
    .so_footer_right {
        flex-direction: column;
        align-items: center;
    }

    .right_btns {
        flex-direction: row;
        gap: 1rem;
    }

    .so_amount_info {
        width: 100%;
    }

    .next_btn {
        width: 100%;
    }
}

.tablemain {
    padding-top: 10px;
    /* border: 1px solid; */
    height: calc(100vh - 390px);
}

.mshipping {
    display: flex;
    gap: 15px;
    align-items: end;
    width: 100%;
    /* border: 1px solid green; */
    /* padding-left: 100px; */
}

.bottomtable {
    flex-direction: column;
    justify-content: flex-end;
    gap: 2px;
    display: flex;
    width: 100%;
    padding-top: 10px;
    /* border: 1px solid green; */
}

.bottomul {
    width: 21vw;
    margin: 0;
    padding: 0;
}

.bottomu2 {
    width: 21vw;
    /* min-height: 5vh;
    max-height: 10vh; */
    /* margin-bottom: 10px; */
    overflow-y: auto;
    margin: 0;
    padding: 0;
}

