       .Card_main {
         width: 100%;
         max-width: 250px;
         display: flex;
         flex-direction: column;
         padding: 10px;
         border-radius: 10px;
         cursor: pointer;
         gap: 5px;
     }

     .Image_card_continer {
         width: 70px;
         height: 70px;
         border-radius: 50%;
         overflow: hidden;
         display: flex;
         align-items: center;
         justify-content: center;
         transition: all 0.3s ease-in-out;
     }

     .Image_card_continer img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         border-radius: 50%;
         transition: transform 0.3s ease-in-out;
     }

     .Image_card_continer:hover {
         transform: scale(1.05);
     }

     .card_Status {
         display: flex;
         align-items: center;
         gap: 10px;
         font-size: 14px;
         font-weight: 500;
     }

     .Card_text {
         font-size: 16px;
         font-weight: 600;
     }

     .Icon_continer {
         display: flex;
         gap: 10px;
     }

     .Icon_continer div {
         cursor: pointer;
     }

     .icon_ContinerIs {
         border-radius: 5px;
         padding: 5px;
     }

     .main_imageUploader_con {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 5px;
         position: relative;
     }

     .drage_icon {
         margin-top: 30px;
     }

     .layout_imageUpload_con {
         width: 100%;
         display: flex;
         flex-direction: column;
         border-radius: 6px;
         overflow: hidden;
         /* border: 1px solid ; */
         position: relative;
     }

     .errorText {
         line-height: 0.5;
     }

     .errorText span {
         font-size: 12px;
         font-weight: 400;
         color: red;
         float: right;
     }

     .image_con {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         text-align: center;
         gap: 10px;
     }

     .daag_drop_text {
         font-size: 14px;
         font-weight: 400;
         width: 100%;
     }

     .image_con>p {
         margin: 0;
         line-height: 1;
         font-size: 12px;
     }

     .image_con>p:nth-of-type(2) {
         font-size: 12px;
         margin-top: 4px;
         margin-bottom: 2px;
     }

     .image_con>p:nth-of-type(1) {
         margin-top: 26px;
     }

     .image_con>p:nth-of-type(3) {
         font-size: 10px;
         margin-bottom: 26px;
     }

     .image_con>p:nth-of-type(3)>span {
         text-decoration: underline;
         cursor: pointer;
         color: #4164E3;

     }

     .browser_fike_text {
         font-size: 10px;
         font-weight: 400;
         margin-bottom: 30px;
     }

     .browser_fike_text span {
         color: #4164E3;
         cursor: pointer;
         border-bottom: 1px solid #4164E3
     }

     .bootem_text {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 10px;
     }

     .bootem_text1 {
         font-size: 10px;
         font-weight: 500;
     }

     .bootem_text2 {
         font-size: 10px;
         font-weight: 500;
         display: flex;
         align-items: center;
         gap: 10px;
         justify-content: space-between;
         width: 100%;
     }

     .bootem_text2 div {
         cursor: pointer;
     }



     .imageContainer {
         width: 100%;
         height: 140px;
         overflow: hidden;
         transition: transform 0.3s ease-in-out;
         cursor: pointer;

     }

     .imageContainer img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: center;
         transition: transform 0.5s ease-in-out;
         display: block;
     }

     .imageContainer:hover img {
         transform: scale(1.05);
     }

     .DropDown_value {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: flex-start;
         padding: 0px;
     }

     .drop_down_list {
         position: absolute;
         background-color: #fff;
         border: 1px solid #e4e7eb;
         border-radius: 10px;
         max-height: 180px;
         overflow-y: auto;
         z-index: 1;
         list-style: none;
         width: 100%;
         padding: 0;
         margin: 0;
         padding: 2px 0;
     }

     .drop_down_Con {
         max-width: 150px;
         width: 100%;
         position: relative;
         border-radius: 5px;
         cursor: pointer;
     }

     .select_icon_con {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 5px 10px;
         border-radius: 5px;
         font-size: 14px;
         font-weight: 400;
         height: 34px;
     }

     .select_value {
         font-size: 12px;
         font-weight: 400;
         text-align: center;
     }

     .drop_down_item {
         padding: 8px 10px;
         font-size: 12px;
         color: #646b88;
         cursor: pointer;
         text-align: left;
         font-weight: 500;
         line-height: 18px;
         border-bottom: 1px solid #e4e7eb;
         transition: background-color 0.2s ease;
     }

     .drop_down_item:hover {
         background-color: #e4e7eb;
     }

     .drop_down_item_dark {
         color: #ffffff;
         border-bottom: 1px solid #1b1d21;
     }

     .drop_down_item_dark:hover {
         background-color: #333;
     }

     .Key_word_container {
         width: 100%;
         display: flex;
         flex-direction: column;
         gap: 5px;
         position: relative;
     }

     .errText {
         width: 100%;
         position: absolute;
         top: 100%;
         right: 0;
     }

     .errText span {
         font-size: 12px;
         font-weight: 400;
         float: right;
         color: red;
     }

     .keyWord_word {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-wrap: wrap;
         gap: 10px;
         background-color: #fff;
         padding: 10px;
         border-radius: 10px;
     }

     .keyWord_word>div {
         line-height: 0;
         padding: 15px 10px;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 10px;
         border-radius: 5px;
         font-size: 14px;
         font-weight: 500;
         /* border: 1px solid; */
         /* cursor: pointer; */
     }

     .inputTage_keyWord_gen {
         width: 100%;
     }

     .inputTage_keyWord_gen input {
         width: 100%;
         outline: none;
         padding: 10px;
         border-radius: 5px;
         font-size: 12px;
         font-weight: 400;
     }

     .borderColorDark {
         border: 1px solid #1B1D21;
     }

     .borderColorLight {
         border: 1px solid #E4E7EB;
     }

     .inputTage_keyWord_gen>input:focus {
         border: 1px solid #4164e3;
     }

     .textarea_container {
         width: 100%;
         display: flex;
         flex-direction: column;
         padding: 0;
         margin: 0;
         margin-bottom: -10px;
     }

     .textarea_container_con {
         width: 100%;
         /* border: 1px solid; */
         position: relative;
     }

     .textarea_container_con p {
         font-size: 12px;
         line-height: 0;
         position: absolute;
         top: 100%;
         right: 0;
         color: red;
     }

     .textarea_container_con textarea {
         width: 100%;
         outline: none;
         border-radius: 5px;
     }

     .textarea_container_con textarea:focus {
         border: 1px solid #4164E3;
     }

     .textarea_textarea_dark {
         border: 1px solid #1B1D21;
         font-size: 12px;
         font-weight: 400;
         padding: 10px;
         color: #fff;
         background-color: #1B1D21;
     }

     .textarea_textarea_Light {
         border: 1px solid #E4E7EB;
         font-size: 12px;
         font-weight: 400;
         padding: 10px;
         color: #000;
         background-color: #fff;
     }


     .textarea_text {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 100%;
         font-size: 14px;
         font-weight: 400;
     }

     .Skeleton_con {
         width: 100%;
     }

     .Skeleton_con>div:nth-of-type(1) {
         width: 50%;
     }

     .loading_sk {
         display: flex;
         align-items: center;
         justify-content: center;
         height: 50px;
     }