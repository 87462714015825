.toastContainer {
    position: absolute;
    top: 5%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast {
    padding: 10px;
    border-radius: 5px;
    transition: opacity 0.5s ease;
    opacity: 1;
    min-width: 200px;
    overflow: hidden;
    width: auto;
    animation: fadeIn 0.3s ease forwards;
    color: #646B88;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 360px;
}

.toast svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
}

.toast .message {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin: 0;
}

.toast_dark {
    background-color: #1B1D21;
    color: #FFF;
}

.hide {
    opacity: 0;
    pointer-events: none;
}

.progressWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    overflow: hidden;
}

.progressBar {
    height: 100%;
    animation: progress 4s linear forwards;
}

.progress_success {
    background-color: #1E7D32;
}

.progress_wrapper_success {
    background-color: rgba(50, 205, 50, 0.3);
}

.progress_error {
    background-color: #C62828;
}

.progress_wrapper_error {
    background-color: rgba(255, 69, 58, 0.3);
}

.progress_info {
    background-color: #1565C0;
}

.progress_wrapper_info {
    background-color: rgba(30, 144, 255, 0.3);
}

.progress_warning {
    background-color: #E65100;
}

.progress_wrapper_warning {
    background-color: rgba(255, 165, 0, 0.3);
}

@keyframes progress {
    from {
        width: 100%;
    }

    to {
        width: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}