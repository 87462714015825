    .table_heading_msg {
    min-width: 24%;
    text-align: left;
}

.order_top_left_go_back {
    display: flex;
    cursor: pointer;
    text-wrap: nowrap;
}

.order_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-top: 10px; */
    width: 100%;
}

.order_top_left_path_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 0;
    text-transform: capitalize;
    margin: 0;
    color: #646B88;
}


.table_txt_msg {
    min-width: 24%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 44px;
}

.table_txt_threeDot {
    min-width: 4%;
    text-align: center;
}

.table_txt_green {
    min-width: 16.66%;
    text-align: center;
    color: #29BB89;
}

.table_txt_yellow {
    min-width: 16.66%;
    text-align: center;
    color: #FFA32F;
}

.table_txt_red {
    min-width: 16.66%;
    text-align: center;
    color: red;
}

/* ---------------------------Create Template---------------------------------- */

.templateName_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    background-color: #1E2022;
    padding: 16px 10px;
    border-radius: 8px;
    position: relative;
}

.doneButton {
    width: 84px;
}

.template_guidelines {
    color: #2196F3;
    margin-right: 20px;
    /* text-decoration: underline; */
    cursor: pointer;
}

.template_change {
    color: #2196F3;
    /* margin-right: 20px; */
    /* text-decoration: underline; */
    cursor: pointer;
}

.templteName {
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 40px;
    line-height: 40;
    border-radius: 5px;
    padding: 0 8px;
    margin-left: 20px;
    min-width: 400px;
    color: #fff;
}

.templateLeft {
    width: 16%;
    padding-right: 16px;
}

.templateCenter {
    width: 64%;
    background-color: #1E2022;
    border-radius: 8px;
    padding: 20px;
    margin-top: 12px;
    overflow-y: scroll;
    max-height: calc(100vh - 164px);
    min-height: calc(100vh - 222px);
}

.templateCenter::-webkit-scrollbar {
    display: none;
}

.templateRight {
    width: 100%;
}

.headerType_select {
    margin-top: 6px;
    background: #000;
    width: 200px;
    height: 36px;
    border-radius: 5px;
    text-align: left;
    padding: 0 5px;
}

.language_select {
    margin-top: 4px;
    background: #000;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    text-align: left;
    padding: 0 5px;
    border: 1px solid #ffffff;
    /* margin-top: 16px; */
}

.templateBody {
    background-color: #000000;
    width: 100%;
    border-radius: 6px;
    padding: 0 5px;
    color: #fff;
    text-transform: capitalize;
}

.templateBody_count {
    font-size: 14px;
    line-height: 14px;
    /* text-align: right; */
}

.templateFooter {
    background-color: #000000;
    width: 100%;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #ffffff;
    margin-bottom: 4px;
    height: 40px;
    color: #fff;
    text-transform: capitalize;
}

.templateButton {
    display: flex;
    background-color: #000000;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #ffffff;
    margin-bottom: 16px;
    position: relative;
}

.addButton {
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.templateContent {
    /* margin-top: 16px; */
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    width: 320px;
    min-height: 50px;
    margin: 16px auto;
    position: relative;
}

.mediadiv {
    background: #ccd0d5;
    width: 100%;
    height: 160px;
    border-radius: 8px;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.darkBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 155px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.header_radio_btn[type="radio"] {
    min-width: 16px;
    min-height: 16px;
    cursor: pointer;
}

.mediaOptions {
    margin: 0 40px 0 3px;
    cursor: pointer;
}

.CallToActionBtn {
    border: 1px solid #BABABA;
    background-color: #1E2022;
    outline: none;
    color: #BABABA;
    width: 100%;
    padding: 0px 5px;
    text-align: left;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    line-height: 20px;
    height: 40px;
}

.typeofAction {
    width: 18%;
    margin-right: 6px;
}

.btnTxt {
    width: 16%;
    margin-right: 6px;
}

.urltype {
    width: 10%;
    margin-right: 6px;
}

.websiteURL {
    width: 56%;
}

.removeBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    cursor: pointer;
}

.txtEditor_btn {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
}

.icon_css {
    margin: 0 10px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.emojis_list {
    position: absolute;
    width: 290px;
    margin-top: 32px;
    right: 0;
    top: 0;
}

.emojis_div {
    height: 270px;
    padding: 10px 0 0 10px;
    overflow-y: scroll;
    padding-bottom: 10px;
    color: #000;
}

.emojis_div ::-webkit-scrollbar {
    display: none;
}

.emojis_icon_css {
    font-size: 24px;
    cursor: pointer;
}


.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

/* 
.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 116px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 68px;
    z-index: 999;
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
} */

/* .modal_backdrop {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
} */

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.dealerNameModal_btn {
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin: auto;
}

.desc_text {
    font-size: 24px;
    margin: 20px 0 50px 0;
    text-align: center;
    width: 100%;
}

.message {
    text-align: center;
    margin: 10px 0 20px 0;
    font-size: 24px;
}

.input_delete {
    background: transparent;
    border: 1px solid #fff;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    margin: auto;
    margin-bottom: 32px;
    color: #fff;
    font-size: 20px;
    max-width: 90px;
    line-height: 20px;
    text-align: center;
}

.input_Mobile_number {
    background: transparent;
    border: 1px solid #fff;
    height: 32px;
    border-radius: 3px;
    display: flex;
    color: #fff;
    font-size: 18px;
    max-width: 146px;
    line-height: 20px;
    text-align: left;
    padding: 0 5px;
    margin-left: 32px;
}

.templateName_alert {
    position: absolute;
    bottom: 0;
    margin-left: 132px;
    font-size: 13px;
    color: red;
}

.error {
    color: red;
    font-size: 12px;
}

.websiteLink {
    text-decoration: none;
    cursor: pointer;
}

.websiteLink:hover {
    color: indigo;
}

.pdfView {
    width: 304px;
    height: 160px;
    overflow: hidden;
    overflow-y: hidden;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
}

.pdf_container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow-y: hidden;
}

.pdf_vertical {
    min-width: 15px;
    position: absolute;
    background-color: #fff;
    height: 162px;
    right: 0;
    z-index: 999;
    top: 14px;
}









/* ================== New css =============== */



.Conitner {
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* border: 1px solid; */
    position: relative;
}

.dateFilter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    /* border: 1px solid; */
}

.dateFilter1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    padding: 0 5px;
}

.sacrch {
    width: 100%;
}


@media (max-width: 700px) {
    .Conitner {
        height: 100vh;
        width: 100%;
        margin: 0px;
    }

    .TopTabBa {
        display: none;
    }

    .iconWrapper {
        display: none;
    }

    .createTemp {
        display: none;
    }

    .Secand_Coninter2 {
        display: none;
    }

    .Secand_Coninter1 {
        width: 100%;
        height: 100vh;
        /* border: 1px solid; */
    }

    .Secand_Coninter {
        display: none;

    }

    .btn_coninter {
        display: none;
    }

    .sarchbar {
        height: 14vh;
        /* border: 1px solid; */
    }
}

/* 
   @media (min: 700px) {
       .mainContinerd1 {
           display: none;
       }
   } */





.Secand_Coninter {
    width: 100%;
    /* height: calc(13% + 20px); */
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    
}

.iconceate {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #4164E3;
    margin-left: 5px;
    cursor: pointer;
    /* color: #6C7A89; */
    /* float: left; */
}

.Authentication {
    font-size: 12px;
}

.TopTabBa {
    width: 100%;
    display: flex;
    align-items: center;
    /* align-items: center;
             justify-content: center; */
}

.TopTabBaconntiner {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;

}

.TopTabBaconntiner1 {
    width: 45%;
}

.Secand_Coninter1 {
    width: calc(100%);
    height: calc(100% - 13% - 40px - 6vh);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: scroll;
    margin-top: 20px;
}

.Secand_Coninter2 {
    position: absolute;
    bottom: 0%;
    width: calc(100% - 10px);
    height: 6vh;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    /* border: 1px solid; */
}

.navigate_Sub_menu {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid; */
    line-height: 1.1;
}

.navigate_span1 {
    font-size: 25px;
    /* margin-left: 0.5vw; */
    margin-top: 0.4vh;
    color: #4164E3;
}

.navigate_span2 {
    font-size: 14px;
    margin-left: 3px;
    margin-top: 0.5vh;
    color: #4164E3;
    font-weight: 500;
}

.navigate_span3 {
    width: 8px;
    height: 8px;
    background-color: #BABABA;
    border-radius: 50%;
    margin: 9px 5px;
}

.navigate_span4 {
    font-size: 13px;
    margin-top: 0.5vh;
    color: #646B88;
    font-weight: 500;
}

.btn_coninter {
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}

.btn_coninter_left {
    width: 49%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.btn_coninter_Right {
    width: 75%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    gap: 0.8vw;
    justify-content: flex-end;
    margin-right: 10px;
}

.AroBtn {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}













.menuWrapper {
    position: relative;
    width: 25%;
    /* border: 1px solid; */
    display: flex;
    gap: 10px;
}

.iconWrapper {
    position: relative;
    /* display: inline-block; */
}

.mainMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0%;
    /* Menu appears just below the icon */
    left: 50px;
    background-color: white;
    /* border: 1px solid #E4E7EB; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 8px;
    border-radius: 8px;
    z-index: 10;
    min-width: 180px;
}

.menuItem {
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.menuItem:hover {
    background-color: #F8F9FC;
    border-radius: 5px;
}

.menuItem_dark {
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.menuItem_dark:hover {
    background-color: #1E2022;
    border-radius: 5px;
}



.subMenu {
    margin-top: 5px;
    padding: 10px;
    background-color: white;
    /* border: 1px solid #E4E7EB; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 100%;
    /* border: 1px solid; */
    /* Submenu appears to the right of the main item */
}

.subMenudark {
    margin-top: 5px;
    padding: 10px;
    background-color: white;
    /* border: 1px solid #E4E7EB; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 100%;
    /* border: 1px solid; */
    /* Submenu appears to the right of the main item */
}

.subMenu div:hover {
    background-color: #F8F9FC;
    border-radius: 5px;
}

.subMenudark div:hover {
    background-color: #1E2022;
    border-radius: 5px;
}

.subMenu div {
    padding: 5px 10px;
    cursor: pointer;
}

.subMenudark div {
    padding: 5px 10px;
    cursor: pointer;
}






















.SerachContiner {
    width: 20vw;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: scroll;
    /* border: 1px solid; */
}

@media (max-width: 700px) {
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
}










h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
}

h2 small {
    font-size: 0.5em;
}

.responsiveTable {
    list-style-type: none;
    padding: 0;
    position: relative;
    /* border: 1px solid red; */

    .tableHeader {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        display: flex;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 10px;
    }

    .tablerow {
        background-color: #ffffff;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
    }

    .col {
        flex: 1;
        padding: 0 10px;
        /* border: 1px solid; */
        font-size: 12px;
    }

    .col1 {
        flex: 1;
        padding: 0 10px;
        /* border: 1px solid; */
        font-size: 12px;
    }

    @media all and (max-width: 700px) {
        .tableHeader {
            display: none;
        }

        .tablerow {
            display: block;
            margin-bottom: 15px;
        }

        .col1 {
            display: none;
        }

        .col {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;

            &:before {
                color: #aaaaaa;
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
                text-align: left;
            }
        }

    }

}

.threeDot {
    /* margin-left: 3vw; */
    cursor: pointer;
    align-self: center;
    /* padding: 2px; */
    width: 20%;
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    position: relative;
}




.subBox {
    width: 10.5rem;
    padding: 10px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 999;
    /* border: 1px solid; */

}



@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* When the subBox is active */
.subBox.active {
    animation: slideIn 0.5s forwards;
    background-color: rebeccapurple;
}

/* Arrow Styles */

/* 
.subBox::before {
    content: '';
    position: absolute;
    top: 37%;
    right: -22px;
    border-width: 10px;
    border-style: solid;
    rotate: 90deg;
    border-width: 11px;
    border-color: transparent transparent rgb(85, 85, 82) transparent;

} */

.subBox.active.dark {
    background-color: #000000;
    border: 1px solid #ffffff;
}

.subBox.active.dark::before {
    transform: rotate(150deg);
    border-color: transparent transparent #000000 transparent;
}



.sub_box_tab {
    width: 100%;
    padding: 8px ;
    cursor: pointer;
    border-radius: 3px;
}

.sub_box_tab span {
    font-size: 14px;
    line-height: 0px;
    margin-top: 0px;
}

.sub_box_tab:hover {
    background-color: #000;
    /* color: #fff; */
    /* color: #4164E3; */
}


.sub_box_tab_dark {
    width: 100%;
    padding: 8px ;
    cursor: pointer;
    border-radius: 3px;
    /* border: 1px solid; */

}

.sub_box_tab_dark span {
    font-size: 14px;
    line-height: 0px;
    margin-top: 0px;
}

.sub_box_tab_dark:hover {
    background-color: #F8F9FC;
    /* color: #000000; */
    /* color: #4164E3; */
}







.containermode {
    width: 90%;
}

.model_text {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}

.btn_Continer {
    width: 100%;
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.mode_btn {
    width: 49%;
}

.mainContinerd {
    width: 100%;
    /* border: 1px solid; */
    display: flex;
}

.mainContinerd1 {
    width: 100%;
    /* border: 1px solid; */
    display: flex;
}

.date {
    width: 80%;
    /* border: 1px solid; */
    position: relative;
}

.DeleteBtn {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 6px;
    /* background-color: #4164E3;
    color: white; */
}












/*  create tabplet css */
.main_continer {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 10px;
    /* border: 1px solid; */
}

.secand_main_continer {
    width: 100%;
    height: 100%;
    /* background-color: #F8F9FC; */
    /* overflow: hidden; */
    /* border: 1px solid; */
    overflow-y: scroll;
}

.topBar2 {
    width: 100%;
}

.go_Back {
    width: 100%;
    display: flex;
}

.Templet_container {
    width: 100%;
    /* margin: 0px 0.8vw; */
    /* height: calc(13% + 20px); */
    padding: 10px;
    /* border: 1px solid rebeccapurple; */
}

.formHeight {
    height: 100%;
    /* border: 1px solid; */
    overflow-y: scroll;
}

.Templet_container1 {
    width: 100%;
    height: calc(100vh - 13% - 40px - 72px);
    /* height: 300px; */
    border-radius: 10px;
    padding: 10px;
    /* border: 1px solid green; */
}

.templateName_continer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* border: 1px solid; */
}

.template_input {
    width: 100%;
    /* border: 1px solid; */
}

.spantext {
    font-size: 12px;
    font-weight: 400;

}

.template_btn_Continer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    /* margin-right: 2vw; */
}

.inputTageDiv {
    width: 30%;
}

.form_continer {
    width: 100%;
    display: flex;
    gap: 10px;
    /* height: 76vh; */
    height: 100%;
    overflow-y: scroll;
    /* border: 1px solid; */
}

.Marketing_continer {
    width: 20%;
    border-radius: 6px;
}

.Header_continer {
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    /* border: 1px solid ; */
    overflow-x: hidden;
}

.Preview_continer {
    width: 30%;
    /* border: 1px solid; */

}

.Marketing_continer_headr {
    width: 100%;
    /* border: 1px solid #ccc */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    background-color: #1B1D21;
    /* border-radius: 6px 6px 0 0; */
}

.Marketing_continer_body {
    margin: 8px 8px;
}

.changeColor {
    color: #4164E3;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
}

.Marketing_Text {
    margin-left: 20%;
    font-style: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
}

.Marketing_Text span {
    font-size: 13px;
    font-weight: 300;
}

.select_Lng {
    width: 100%;
}

.select_box {
    width: 100%;
    /* padding: 10px; */
    border-radius: 5px;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 10px;
    /* padding-right: 20px; */
    padding: 10px 40px 10px 10px;
    /* Top, Right, Bottom, Left padding */
    appearance: none;
    /* Default arrow ko remove karne ke liye */
    background-repeat: no-repeat;
    /* Arrow icon ko repeat nahi karna */
    background-position: right 10px center;
    /* Arrow icon ka position set karne ke liye */
    font-size: 12px;
    font-weight: 400;
}

.select_box:hover {
    background-color: red;
}

.select_box_tag {
    width: 100%;
    /* border: 1px solid; */
}

.create_Tamplet_Continer {
    width: 100%;
    /* border: 1px solid; */
}

.create_tem_selectBox {
    width: 60%;
    margin: 8px 10px;
    box-sizing: border-box;
}

.create_tem_TypeOfTamp {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 8px 10px;
    gap: 5%;
    align-items: center;
    box-sizing: border-box;
}

.create_tem_TypeOfTamp1 {
    width: 100%;
    display: flex;
    margin: 0px 10px;
    gap: 5%;
    align-items: center;
}

.create_tem_Text {
    width: 100%;
    /* border: 1px solid ; */
    margin: 5px 8px;
    display: flex;
    flex-direction: column;
}

.create_tem_Text_Span1 {
    font-size: 14px;
    font-weight: 500;
    color: #646B88;
}

.create_tem_Text_Span2 {
    font-size: 13px;
    font-weight: 400;
    color: #000;
}

.create_tem_MediaFile {
    width: 100%;
}

.create_tem_MediaFile_text {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
}

.create_tem_MediaText_Con {
    width: 100%;
    /* border: 1px solid; */
    padding: 8px 10px;
}

.create_temp_footer_input {
    width: 100%;
    /* margin: 8px 10px; */
    box-sizing: border-box;
}

.create_temp_footer_input1 {
    width: 97%;
    margin: 10px 10px;
    box-sizing: border-box;
}


.Preview_Text {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    padding: 10px;
    border-radius: 6px 6px 0px 0px;
}

.Preview_continer_main {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0px 0px;
}

.Preview_continer_media {
    width: 100%;
    height: auto;
}

.Preview_continer_media1 {
    width: 94%;
    height: 180px;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    /* background-color:#6C7A89 ; */
}

.can {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image {
    object-fit: cover;
    width: 100%;
    height: 190px;
    overflow: hidden;
}

.SelectPdf {
    object-fit: cover;
    width: 100%;
    height: 180px;
    border: none;
    overflow: hidden;
    display: block;
}

iframe {
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

iframe::-webkit-scrollbar {
    display: none;
}

.IframeWrapper {
    width: 100%;
    height: 180px;
    /* Adjust this to fit your needs */
    overflow: hidden;
    /* Hides scrollbars */
}




.Preview_continer_media2 {
    width: 94%;
    height: auto;
    margin: 0 auto;
    margin-top: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-all;
    /* background-color:#6C7A89 ; */
}

.con {
    width: 100%;
    /* border: 1px solid; */
    padding: 10px 0px 0px 10px;
}

.position {
    position: relative;
}

.appliyfilter {
    position: absolute;
    width: auto;
    /* border: 1px solid; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    z-index: 999;
    padding: 8px;
    font-size: 14px;
    width: 100px;
}

.appliyfilter span {
    cursor: pointer;
}

.CategoryFilter {
    position: relative;
}

.appliyfilterHover :hover {
    color: #4164E3;
    cursor: pointer;
}