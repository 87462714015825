.navbar1,
.navbar {
  background-color: #f8f9fc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 70px; */
  margin: 0 auto;
  padding: 0.5rem 1.2rem;
}

@media (max-width: 1061px) {
  .navbar_Search {
    display: none;
  }

  /* .navbar_Right {
    width: 100%;
  } */
}

.navbar_Search h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #646b88;
  margin: 0;
}

.navbar_Right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: auto;
}

.navbar_Links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.navbar_Links>div>svg {
  cursor: pointer;
  height: 24px;
  width: 24px;
  /* background: red; */
}

.navbar_Profile_Section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 0.7rem;
  gap: 10px;
  cursor: pointer;
  margin-left: 40px;
  position: relative;
}

.navbar_Profile_Section img {
  height: 100%;
  max-height: 40px;
  object-fit: contain;
  user-select: none;
}

.first_name {
  width: 25px;
  height: 25px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* padding: 5px; */
}

.navbar_Profile_Section img,
.navbar_Profile_Section p {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.navbar_Profile_Section p {
  margin: 0;
  line-height: 15.08px;
  font-size: 12px;
  font-weight: 500;
}

.hidden_designations {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 10px 0px #00000040;
  z-index: 1;
  white-space: nowrap;
  border-radius: 10px;
  text-align: left;
  opacity: 0;
  transform: translateY(-10px);
  transition: transform 0.3s ease, opacity 0.3s ease, max-height 0.3s ease;
}

.hidden_designations.open {
  max-height: 500px;
  opacity: 1;
  transform: translateY(0);
}

.hidden_designations_list {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, padding 0.3s ease, margin 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hidden_designations_list svg {
  height: 24px;
  width: 24px;
}

.hidden_designations_list:hover {
  background-color: #F8F9FC;
}

.hidden_designations_list.hidden_designations_list_dark:hover {
  background-color: #1B1D21;
}


.total_Unread_Chat {
  position: absolute;
  top: -4px;
  left: 20px;
  background: rgba(198, 38, 38, 1);
  color: #fff;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.con {
  background-color: #1b1d21;
  width: 230px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-out;

}

.con1 {
  background-color: #1b1d21;
  transition: width 0.3s ease-in;
  height: 100vh;
}

.leftMenu {
  width: 190px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 4px;
  transition: width 0.3s ease;
  height: 100vh;
}

.leftMenu::-webkit-scrollbar {
  display: none;
}

.leftMenuToggled {
  width: 80px;
  transition: width 0.3s ease;
}

.menuItem_div_toggled {
  justify-content: center;
  width: 60%;
}

/* Responsive Styles */
@media (max-width: 700px) {
  .navbar1 {
    background-color: #fff;
  }

  .navbar_Search {
    display: none;
  }

  .navbar_Right {
    display: none;
  }

  .lg_screen {
    display: none;
    height: calc(100vh - 70px);
  }

  .mdSccreen {
    display: block;
    height: calc(100vh - 70px);
  }

  .md_continer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: 100%;
  }

  .md_menu_text {
    font-size: 19px;
    font-weight: 700;
    color: #000000;
  }

  .md_menu_text_dark {
    font-size: 19px;
    font-weight: 700;
    color: #ffffff;
  }

  .md_image {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .main_Image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 700px) {
  .md_continer {
    display: none;
  }

  .lg_screen {
    display: block;
    height: calc(100vh - 70px);
  }

  .mdSccreen {
    display: none;
    height: calc(100vh - 70px);
  }
}

/* Drawer.module.css */
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 75%;
  /* Adjust width as needed */
  background-color: #2c2e33;
  /* Adjust background color */
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  z-index: 1000;
}

.drawer_dark {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 75%;
  /* Adjust width as needed */
  background-color: #ffffff;
  /* Adjust background color */
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  z-index: 1000;
}

.drawer.open {
  transform: translateX(0);
}

.drawer_dark.open {
  transform: translateX(0);
}

.drawerContent {
  padding: 20px;
  color: #0f0f0f;
  /* Text color */
  width: 100%;
  /* border: 1px solid; */
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.drawerContentMain {
  width: 100%;
  /* border: 1px solid; */
}

.md_logoContiner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.md_linkContiner {
  /* border: 1px solid; */
  height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.linkTahg {
  width: 100%;
  /* border: 1px solid; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  margin-top: 10px;
  box-sizing: border-box;
  margin-left: 2px;
  height: 28px;
}

.linkTahg_dark {
  width: 100%;
  /* border: 1px solid; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  margin-top: 10px;
  box-sizing: border-box;
  margin-left: 2px;
  height: 28px;
}

.md_drower_text {
  color: #646b88;
  box-sizing: border-box;
}

.linkTahg:hover {
  width: 95%;
  outline: 0.2px solid #232529;
  border-radius: 7px;
  background-color: #232529;
  color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
}

.linkTahg_dark:hover {
  width: 95%;
  outline: 0.2px solid #f5f6fa;
  border-radius: 7px;
  background-color: #f5f6fa;
  color: #000000;
  box-sizing: border-box;
  cursor: pointer;
}

.linkTahg:hover .md_drower_text {
  color: #ffffff;
  /* Text color when box is hovered */
}

.linkTahg_dark:hover .md_drower_text {
  color: #000000;
  /* Text color when box is hovered */
}

.iconHover:hover {
  color: white;
}
.whatAppChatCount{
  max-width: 1620px;
  width: 100%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 100px;
  z-index: 999;
  right: 10px;
}