.iconImage {
  width: 24px;
  height: 24px;
}

.leftMenuToggled {
  width: 100px;
  transition: width 0.3s ease;
}

.admin_menu_logo {
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  border-bottom: 1px solid #646B88;
}

.admin_menu_logo_dark {
  border-bottom: 1px solid #fff;
}

.admin_menu_logo>div>svg {
  cursor: pointer;
}

.menuItem_div_con {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-y: auto;
  padding: 10px 0;
}

/* .menuItem_div_con_closed {
  padding-top: 50rem;
} */

.menuItem_div {
  background-color: #fff;
  color: #646b88;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* align-items: flex-end; */
  width: 90%;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 10px;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: justify-content 0.3s ease;
  position: relative;
  transition: transform 0.3s ease;
}

.active_div {
  background-color: #f5f6fa;
}

.active_div_Dark {
  background-color: #232529 !important;
}

.menuItem_div_toggled {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  gap: 1px;
}

.hoverpath {
  position: relative;
}

.menuItem_div:hover {
  transform: scale(1.05);
}

.hoverPathname {
  position: absolute;
  left: 0px;
  color: rebeccapurple;
  background-color: #bebebe;
  width: 90px;
  height: 40px;
  text-align: center;
  border-radius: 5px;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItem_div:hover .hoverPathname {
  transform: translateY(250);
}