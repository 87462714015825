.am {
    height: calc(100vh - 80px);
    width: calc(100% - 20px);
    margin: 10px auto;
    background-color: #f8f9fc;
    border: 1px solid #e4e7eb;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}

.am_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.am_top p {
    margin: 0;
}

.am_top_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.am_top_left_go_back {
    display: flex;
    cursor: pointer;
}

.am_go_back_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    padding-top: 0.3rem;
}

.am_go_back_text_dark {
    color: #fff !important;
    padding-top: 0.3rem;
}

.am_top_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    color: #646B88;
}

.am_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #e4e7eb;
    border-radius: 15px;
}

.am_profile_text {
    display: flex;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.am_photo {
    width: 100px;
    height: 100px;
}

.am_photo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.First_text{
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 600;
}

.am_profile_text_container {
    display: flex;
    gap: 3rem;
}

.am_work_status_section {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.mandatory {
    color: #f93e3e;
}

.am_assignments {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    overflow: hidden;
    max-height: 500px;
    overflow-y: auto;
}

.am_assignments_left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
}

.am_assignments_right {
    width: 30%;
    height: 100%;
}

.am_assignment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #e4e7eb;
    border-radius: 15px;
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
}

.am_assignment p {
    margin: 0;
    font-weight: 600;
}

.am_list_data {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    height: 83px;
    overflow-y: auto;
    width: 100%;
}

.am_footer_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    background-color: #ffffff;
    width: 100%;
    padding: 0.8rem;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px -6px 10px 0px #0000001a;
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 11;
}

.progress_bar_mob {
    overflow-y: auto;
    max-height: 100%;
    width: 100%;
}

.am_footer_mob_dark {
    box-shadow: 0px -6px 10px 0px #0000001A;
    background-color: #2D2F34;
}

.am_dark {
    background-color: #2c2e33;
    border: none;
}

.warehouse_list_dark {
    color: #fff;
}

.am_bg_dark {
    background-color: #232529;
    border: none;
}

.am_dark_mode_text {
    color: #ffffff !important;
}

.am_light_mode_text {
    color: #646b88;
}

.am_dark_mode_link {
    color: #4179FF;
    font-weight: 500;
}

.am_light_mode_link {
    color: #0100e4;
    font-weight: 500;
}

.set_theme {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.set_theme>p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 1350px) {
    .am {
        gap: 0.5rem;
    }

    .am_action_center {
        top: 1rem;
    }

    .am_profile_text {
        gap: 2rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .am_profile_text_container {
        gap: 2rem;
        flex-wrap: wrap;
    }

    .am_assignments {
        gap: 0.5rem;
        flex-direction: column;
    }

    .am_assignments_left,
    .am_assignments_right {
        gap: 0.5rem;
        width: 100%;
    }
}

@media (max-width: 700px) {
    .am {
        height: 100vh;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        overflow: auto;
        padding: 1rem 0.5rem;
        padding-bottom: 30rem;
    }

    .empty_div_for_spacing {
        height: 400px;
        width: 100%;
        display: none;
        background-color: green;
    }

    .am_top {
        display: none;
    }

    .am_photo {
        height: 60px;
        width: 60px;
    }

    .am_assignments {
        overflow: visible;
        overflow-y: visible;
    }

    .am_assignments {
        overflow: unset;
        height: 100%;
        width: 100%;
        overflow-y: unset;
    }

    .am_assignments_left {
        width: 100%;
    }

    .am_assignment {
        width: 100%;
        max-height: 320px;
        overflow-y: unset;
    }

    .am_assignments_right {
        width: 100%;
    }

    .am_list_data {
        height: 100%;
        overflow-y: unset;
    }

    .am_footer_mob {
        display: flex;
    }
}

@media (max-width: 400px) {
    .am_details,
    .am_assignment {
        padding: 10px;
    }
}